import { createSlice } from '@reduxjs/toolkit';

import { signIn, signUp } from './userActions';
import { IUserState } from '../../interfaces/user.interface';

const accessToken = localStorage.getItem('access-token')
  ? localStorage.getItem('access-token')
  : null;

const initialState: IUserState = {
  accessToken,
  error: null,
  loading: false,
  message: null,
  success: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    cleanError: (state) => {
      state.error = null;
    },
    cleanMessage: (state) => {
      state.message = null;
    },
    signOut: (state) => {
      localStorage.removeItem('access-token');
      state.loading = false;
      state.accessToken = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signIn.pending, (state) => {
      state.error = null;
      state.loading = true;
      state.success = false;
    });
    builder.addCase(signIn.fulfilled, (state, { payload }) => {
      state.accessToken = payload;
      state.loading = false;
      state.success = true;
    });
    builder.addCase(signIn.rejected, (state, { payload }) => {
      state.error = payload as string;
      state.loading = false;
      state.success = false;
    });
    builder.addCase(signUp.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(signUp.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.success = true;
    });
    builder.addCase(signUp.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as string;
    });
  },
});

export const { cleanError, cleanMessage, signOut } = userSlice.actions;

export default userSlice.reducer;
