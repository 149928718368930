import React from 'react';
import { Typography } from '@mui/material';

import { CopyrightProps } from './Copyright.props';

const Copyright = ({ sx = [] }: CopyrightProps): JSX.Element => {
  return (
    <Typography
      variant="body2"
      align="center"
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
    >
      {'© '}
      {new Date().getFullYear()} NextSteps
    </Typography>
  );
};

export default Copyright;
