import React, { useEffect } from 'react';

import { withHomeLayout } from '../../layouts/home/HomeLayout';

const CookiePolicy = (): JSX.Element => {
  const divProps = {
    name: 'termly-embed',
    'data-id': '325c451b-5fcb-42ed-865a-41998514f5d6',
    'data-type': 'iframe',
  };
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://app.termly.io/embed-policy.min.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return <div {...divProps}></div>;
};

export default withHomeLayout(CookiePolicy);
