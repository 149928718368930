import {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  RawAxiosRequestHeaders,
} from 'axios';
import { Location, NavigateFunction } from 'react-router';

import { icons } from './icons';
import { IBlog } from '../interfaces/blog.interface';
import { INavbarItem } from '../interfaces/navbar.interface';
import { ITitle } from '../interfaces/common.interface';

export declare type Variant =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'dark'
  | 'light'
  | string;

const formatMoney = (money: number): string =>
  money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const priceGbp = (price: number): string =>
  '£'.concat(formatMoney(price));
export const priceUsd = (price: number): string =>
  '$'.concat(formatMoney(price));

export const mobileRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const websiteRegExp =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

export const navbar: INavbarItem[] = [
  {
    id: 'tools',
    title: 'Tools',
    subItems: [
      // {
      //   id: 'netSalary',
      //   title: 'Net salary calculator',
      //   subTitle: 'Calculate your net income',
      //   route: '/tools/net-salary-calc',
      //   icon: icons.faCalculatorSimple,
      // },
      // {
      //   id: 'bestSalary',
      //   title: 'Best salary ranking',
      //   subTitle: 'Get to your options better',
      //   route: '/tools/best-salary',
      //   icon: icons.faChartMixed,
      // },
      {
        id: 'resumeBuilder',
        title: 'Resume builder',
        subTitle: 'Tailor your resume to individual jobs',
        route: '/tools/resume-builder',
        icon: icons.faCubesStacked,
      },
    ],
  },
  // {
  //   id: 'blog',
  //   title: 'Blog',
  //   route: '/blog',
  //   icon: icons.faPenNib,
  // },
  {
    id: 'pricing',
    title: 'Pricing',
    route: '/#pricing',
    icon: icons.faTag,
  },
];

export const blog: IBlog = {
  posts: [
    {
      id: 'blogPost1',
      title: 'Designing a system to run a free avocado campaign',
      description:
        'This service will track the number of avocados available to order, and the customers who have already claimed one.',
      linkText: 'Continue reading…',
    },
    {
      id: 'blogPost2',
      title: 'Featured post',
      description:
        'This is a wider card with supporting text below as a natural lead-in to additional content.',
      linkText: 'Continue reading…',
    },
  ],
};

export const getAxiosConfig = (): AxiosRequestConfig => {
  return {
    headers: {
      'Content-Type': 'application/json',
    } as RawAxiosRequestHeaders,
    timeout: 1000,
    timeoutErrorMessage: 'The request timed out.',
  } as AxiosRequestConfig;
};

export const axiosErrorHandler = (
  error: AxiosError,
  location: Location,
  navigate: NavigateFunction,
): string => {
  if (window.navigator.onLine && 'ERR_NETWORK' === error.code) {
    navigate(location.pathname, {
      state: { errorStatusCode: 500 },
    });

    return error.message;
  }

  if (!window.navigator.onLine && 'ERR_NETWORK' === error.code) {
    navigate(location.pathname, {
      state: { errorStatusCode: 12163 },
    });

    return error.message;
  }

  if ('ECONNABORTED' === error.code) {
    return `Problem: ${error.message}`;
  }

  const axiosResponse = error.response as AxiosResponse;

  if (400 === axiosResponse.data.statusCode) {
    return `Validation problem: ${axiosResponse.data.message}`;
  } else if (401 === axiosResponse.data.statusCode) {
    if ('/signin' === location.pathname) {
      return `Problem: ${axiosResponse.data.message}`;
    } else {
      navigate(location.pathname, { state: { errorStatusCode: 401 } });
    }
  } else if (404 === axiosResponse.data.statusCode) {
    navigate(location.pathname, { state: { errorStatusCode: 404 } });
  } else if (409 === axiosResponse.data.statusCode) {
    // The HTTP 409 Conflict response status code indicates a request conflict
    // with the current state of the target resource.
    return axiosResponse.data.message;
  } else if (429 === axiosResponse.data.statusCode) {
    navigate(location.pathname, { state: { errorStatusCode: 429 } });
  } else if (500 === axiosResponse.data.statusCode) {
    navigate(location.pathname, { state: { errorStatusCode: 500 } });
  } else if (501 === axiosResponse.data.statusCode) {
    navigate(location.pathname, { state: { errorStatusCode: 501 } });
  } else if (503 === axiosResponse.data.statusCode) {
    navigate(location.pathname, { state: { errorStatusCode: 503 } });
  }

  return axiosResponse.data.message;
};

export const sortItemsWithOther = (items: ITitle[]): ITitle[] => {
  const otherItem = items.find((item) => 'other' === item.title.toLowerCase());
  const allButOther = items.filter(
    (item) => 'other' !== item.title.toLowerCase(),
  );

  allButOther.sort((a, b) => (a.title < b.title ? -1 : 1));

  return otherItem ? [...allButOther, otherItem] : [...allButOther];
};
