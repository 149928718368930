import axios, { AxiosRequestConfig } from 'axios';

import { IUser } from '../interfaces/user.interface';
import { ICareerGoal } from '../interfaces/career-goal.interface';
import { IProfessionalArea } from '../interfaces/professional-area.interface';
import { IRegistrationForm } from '../interfaces/registration.interface';
import { IResume } from '../interfaces/resume.interface';
import withConfigInterceptor from '../interceptors/configInterceptor';

export const API_BASE_URL =
  process.env.REACT_APP_API_DOMAIN || 'http://localhost:5000';
const APP_BASE_URL = process.env.REACT_APP_DOMAIN || 'http://localhost:3000';

const httpClient = axios.create({
  baseURL: API_BASE_URL,
});

withConfigInterceptor(httpClient);

export const urls = {
  auth: {
    signIn: '/api/auth/signin',
    signUp: '/api/auth/signup',
  },
  professionalArea: {
    resource: '/api/professional-area',
  },
  careerGoal: {
    resource: '/api/career-goal',
  },
  registration: {
    resource: '/api/registration',
  },
  resume: {
    resource: '/api/resume',
  },
};

const API = {
  auth: {
    signIn: async (formData: IUser) =>
      await httpClient.post(urls.auth.signIn, { ...formData }),
    signUp: async (formData: IUser) =>
      await httpClient.post(urls.auth.signUp, { ...formData }),
  },
  careerGoal: {
    create: async (formData: ICareerGoal) =>
      await httpClient.post(urls.careerGoal.resource, { ...formData }),
    get: async () => await httpClient.get(urls.careerGoal.resource),
  },
  professionalArea: {
    create: async (formData: IProfessionalArea) =>
      await httpClient.post(urls.professionalArea.resource, { ...formData }),
    get: async () => await httpClient.get(urls.professionalArea.resource),
  },
  registration: {
    create: async (formData: IRegistrationForm, config?: AxiosRequestConfig) =>
      await httpClient.post(
        urls.registration.resource,
        { ...formData },
        config,
      ),
  },
  resume: {
    create: async (formData: IResume) =>
      await httpClient.post(urls.resume.resource, {
        ...formData.contact,
        ...formData.description,
        ...formData.knowledge,
        ...formData.education,
        ...formData.experience,
      }),
  },
};

export default API;
