import React from 'react';
import { motion } from 'framer-motion';

import { navbar } from '../../helpers/utils';
import { NavbarItem } from '../NavbarItem/NavbarItem';

export const Navbar = (): JSX.Element => {
  return (
    <motion.div
      style={{
        display: 'flex',
        justifyContent: 'center',
        borderWidth: '1px',
        minWidth: 400,
      }}
    >
      {navbar.map((item) => (
        <NavbarItem key={item.title} item={item} />
      ))}
    </motion.div>
  );
};
