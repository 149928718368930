import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Container,
  CssBaseline,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { icons } from '../../helpers/icons';
import { IUser } from '../../interfaces/user.interface';
import { signIn } from '../../features/user/userActions';
import { cleanError } from '../../features/user/userSlice';
import { withHomeLayout } from '../../layouts/home/HomeLayout';
import { userValidationSchema } from '../../features/user/userValidations';

const formWrapperStyle = {
  marginTop: 8,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
} as const;

const SignIn = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { error, loading, success } = useAppSelector((state) => state.user);
  const [showPassword, setShowPassword] = useState<Boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IUser>({
    resolver: yupResolver(userValidationSchema),
  });

  useEffect(() => {
    dispatch(cleanError());
  }, []);

  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordMouseDown = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const onSubmit = (formData: IUser) => {
    dispatch(signIn({ location, navigate, user: formData }));
    if (success) {
      navigate('/dashboard', { state: {} });
    }
  };

  return (
    <Box>
      <CssBaseline />
      <Container component="main" maxWidth="xs">
        <Box sx={formWrapperStyle}>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <FontAwesomeIcon icon={icons.faRightToBracket} />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{ mt: 1 }}
          >
            <FormControl sx={{ mt: 2 }} fullWidth>
              <InputLabel
                htmlFor="username-outlined-input"
                error={!!errors.username}
              >
                Username
              </InputLabel>
              <Controller
                name="username"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    id="username-outlined-input"
                    label="Username"
                    autoComplete="email"
                    autoFocus
                    error={!!errors.username}
                  />
                )}
              />
              <FormHelperText
                id="username-outlined-input-helper-text"
                error={!!errors.username}
              >
                {errors.username ? errors.username.message : '\u00a0'}
              </FormHelperText>
            </FormControl>
            <FormControl sx={{ mt: 2 }} fullWidth>
              <InputLabel
                htmlFor="password-outlined-input"
                error={!!errors.password}
              >
                Password
              </InputLabel>
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    id="password-outlined-input"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleShowPasswordClick}
                          onMouseDown={handlePasswordMouseDown}
                          sx={{ width: 40 }}
                        >
                          {showPassword ? (
                            <FontAwesomeIcon icon={icons.faEyeSlash} />
                          ) : (
                            <FontAwesomeIcon icon={icons.faEye} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    autoComplete="email"
                    error={!!errors.password}
                  />
                )}
              />
              <FormHelperText
                id="password-outlined-input-helper-text"
                error={!!errors.password}
              >
                {errors.password ? errors.password.message : '\u00a0'}
              </FormHelperText>
            </FormControl>
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              disabled={loading}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link variant="body2" component={RouterLink} to="/signup">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default withHomeLayout(SignIn);
