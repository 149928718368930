import React from 'react';
import { Controller } from 'react-hook-form';
import { Box, FormControl, FormHelperText, TextField } from '@mui/material';

import { DescriptionStepProps } from './DescriptionStep.props';

export const DescriptionStep = ({
  parentPrefix,
  control,
  errors,
}: DescriptionStepProps): JSX.Element => {
  return (
    <Box component="form" noValidate>
      <FormControl fullWidth>
        <Controller
          name="description.info"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              id="info-text-field"
              error={!!errors.description?.info}
              aria-invalid={!!errors.description?.info}
              label="Info"
              autoFocus
              multiline
              rows={15}
              color="primary"
            />
          )}
        />
        <FormHelperText
          id="info-text-field-helper-text"
          error={!!errors.description?.info}
        >
          {errors.description?.info
            ? errors.description?.info?.message
            : '\u00a0'}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};
