import React from 'react';
import {
  CssBaseline,
  Grid,
  SvgIcon,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';

import { ArrowLink } from '../../components/ArrowLink/ArrowLink';
import { ReactComponent as HeroIntroSvg } from './hero_intro.svg';

export const Intro = (): JSX.Element => {
  const theme = useTheme<Theme>();
  const svgStyle = [
    {
      width: 'auto',
      height: 'auto',
    },
    {
      '& #Vector_26': {
        fill: theme.palette.secondary.main,
      },
      // '&:hover #Vector_26': {
      //   fill: theme.palette.primary.main,
      // },
      '& #Vector_40': {
        fill: theme.palette.secondary.main,
      },
      // '&:hover #Vector_40': {
      //   fill: theme.palette.primary.main,
      // },
    },
  ] as const;

  return (
    <Grid
      container
      component="main"
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ mx: { md: 0, lg: 8 }, my: 4, width: 'auto' }}
    >
      <CssBaseline />
      <Grid item xs={12} sm={5} md={6} lg={7}>
        <SvgIcon component={HeroIntroSvg} viewBox="0 0 832 830" sx={svgStyle} />
      </Grid>
      <Grid item xs={12} sm={7} md={6} lg={5}>
        <Grid container component="section" rowSpacing={1}>
          <Grid item component="header">
            <Typography component="h1" variant="h2">
              Personal career agent for software engineers and data scientists
            </Typography>
          </Grid>
          <Grid item component="div">
            <Typography variant="body1">
              We’ll help on every stage from building an appealing resume and
              providing succinct preparation materials all the way to salary
              negotiations and beyond.
            </Typography>
          </Grid>
          <Grid item component="footer">
            <div>
              <ArrowLink
                variant="secondary"
                title="Start now"
                route="/registration-form"
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
