import * as yup from 'yup';

import {
  EMAIL_VALIDATION_ERROR,
  PASSWORD_MAX_LENGTH_VALIDATION_ERROR,
  PASSWORD_MIN_LENGTH_VALIDATION_ERROR,
  PASSWORD_REQUIRED_VALIDATION_ERROR,
  USERNAME_REQUIRED_VALIDATION_ERROR,
} from './userConstants';

export const userValidationSchema = yup.object().shape({
  username: yup
    .string()
    .required(USERNAME_REQUIRED_VALIDATION_ERROR)
    .email(EMAIL_VALIDATION_ERROR),
  password: yup
    .string()
    .required(PASSWORD_REQUIRED_VALIDATION_ERROR)
    .min(8, PASSWORD_MIN_LENGTH_VALIDATION_ERROR)
    .max(32, PASSWORD_MAX_LENGTH_VALIDATION_ERROR),
});
