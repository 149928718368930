import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import API from '../../helpers/api';
import { axiosErrorHandler } from '../../helpers/utils';
import {
  ICreateRegistrationRequest,
  ICreateRegistrationResponse,
} from '../../interfaces/registration.interface';

export const createRegistration = createAsyncThunk<
  ICreateRegistrationResponse,
  ICreateRegistrationRequest,
  { rejectValue: unknown }
>(
  'registration/create',
  async (
    { location, navigate, formData }: ICreateRegistrationRequest,
    { rejectWithValue },
  ) => {
    try {
      const { data } = await API.registration.create(formData);

      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;

        return rejectWithValue(
          axiosErrorHandler(axiosError, location, navigate),
        );
      } else {
        return rejectWithValue(error);
      }
    }
  },
);
