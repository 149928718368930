import React from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import FontAwesomeSvgIcon from '../FontAwesomeSvgIcon/FontAwesomeSvgIcon';

import { ErrorContainerProps } from './ErrorContainer.props';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ErrorContainer = ({
  buttonTitle,
  description,
  errorCode,
  errorIcon,
  routeHandler,
  title,
}: ErrorContainerProps): JSX.Element => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridAutoFlow: 'row',
        alignItems: 'center',
        justifyItems: 'center',
        rowGap: 3,
        my: 10,
        mx: { xs: 2, md: 4 },
      }}
    >
      <Box
        sx={{
          display: { xs: 'grid', md: 'none' },
          gridTemplateColumns: 'auto 1fr auto',
          alignItems: 'center',
          justifyItems: 'center',
          columnGap: { xs: 1, md: 2 },
          gridAutoFlow: 'column',
        }}
      >
        {errorIcon ? (
          <Typography component="h1" variant="h4">
            <FontAwesomeSvgIcon icon={errorIcon} />
          </Typography>
        ) : (
          <Typography component="h1" variant="h4">
            {errorCode}
          </Typography>
        )}
        <Divider orientation="vertical" flexItem />
        <Typography component="h1" variant="h4">
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          display: { xs: 'none', md: 'grid' },
          gridTemplateColumns: 'auto 1fr auto',
          alignItems: 'center',
          justifyItems: 'center',
          columnGap: { xs: 1, md: 2 },
          gridAutoFlow: 'column',
        }}
      >
        {errorIcon ? (
          <Typography component="h1" variant="h1">
            <FontAwesomeIcon icon={errorIcon} />
          </Typography>
        ) : (
          <Typography component="h1" variant="h1">
            {errorCode}
          </Typography>
        )}
        <Divider orientation="vertical" flexItem />
        <Typography component="h1" variant="h1">
          {title}
        </Typography>
      </Box>
      <Typography component="h2" variant="body1">
        {description}
      </Typography>
      <Button variant="outlined" onClick={routeHandler}>
        {buttonTitle}
      </Button>
    </Box>
  );
};

export default ErrorContainer;
