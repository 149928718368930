import React from 'react';
import {
  AppBar,
  Box,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { icons } from '../../helpers/icons';
import { navbar } from '../../helpers/utils';
import { INavbarItem } from '../../interfaces/navbar.interface';
import { ArrowLink } from '../../components/ArrowLink/ArrowLink';
import { Navbar } from '../../components/Navbar/Navbar';

const Header = (): JSX.Element => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null,
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (Boolean(anchorElNav)) {
      setAnchorElNav(null);
    } else {
      setAnchorElNav(event.currentTarget);
    }
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const buildMenuItem = (item: INavbarItem) => {
    return item.subItems ? (
      <MenuItem key={item.id} onClick={handleCloseNavMenu}>
        <List component="div" disablePadding>
          <Divider sx={{ pl: 4 }} textAlign="left">
            {item.title}
          </Divider>
          {item.subItems.map((subItem: INavbarItem) => (
            <ListItemButton
              key={subItem.id}
              sx={{ pl: 8 }}
              component="a"
              href={subItem.route}
            >
              <ListItemIcon>
                <FontAwesomeIcon
                  icon={subItem.icon ? subItem.icon : icons.faHome}
                />
              </ListItemIcon>
              <ListItemText primary={subItem.title} />
            </ListItemButton>
          ))}
        </List>
      </MenuItem>
    ) : (
      <MenuItem
        key={item.id}
        onClick={handleCloseNavMenu}
        component="a"
        href={item.route}
      >
        <ListItemButton sx={{ pl: 4 }}>
          <ListItemIcon>
            <FontAwesomeIcon icon={item.icon ? item.icon : icons.faHome} />
          </ListItemIcon>
          <ListItemText primary={item.title} />
        </ListItemButton>
      </MenuItem>
    );
  };

  return (
    <AppBar position="static" sx={{ px: 4 }}>
      <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="/"
          sx={{
            fontWeight: 600,
            textDecoration: 'none',
            color: (theme) => theme.palette.text.primary,
          }}
        >
          NextSteps
        </Typography>
        <Box sx={{ flexGrow: 0, display: { xs: 'flex', lg: 'none' } }}>
          <IconButton
            size="large"
            aria-label="menu"
            sx={{ mr: -1.5 }}
            onClick={handleOpenNavMenu}
          >
            {Boolean(anchorElNav) ? (
              <FontAwesomeIcon icon={icons.faXmarkLarge} />
            ) : (
              <FontAwesomeIcon icon={icons.faBars} />
            )}
          </IconButton>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            disableAutoFocusItem={true}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
          >
            {navbar.map((item) => buildMenuItem(item))}
            <Divider />
            <MenuItem component="a" href="/registration-form">
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <FontAwesomeIcon icon={icons.faPlay} />
                </ListItemIcon>
                <ListItemText>Start now</ListItemText>
              </ListItemButton>
            </MenuItem>
          </Menu>
        </Box>
        <Box sx={{ flexGrow: 0, display: { xs: 'none', lg: 'flex' } }}>
          <Navbar />
        </Box>
        <Box sx={{ flexGrow: 0, display: { xs: 'none', lg: 'flex' } }}>
          <ArrowLink
            route="/registration-form"
            title="Start now"
            variant="secondary"
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
