import React, { useState } from 'react';
import { Box, Theme, Typography, useTheme } from '@mui/material';
import { motion } from 'framer-motion';

import { NavbarSubItem } from '../NavbarSubItem/NavbarSubItem';
import { NavbarItemProps } from './NavbarItem.props';

export const NavbarItem = ({ item }: NavbarItemProps) => {
  const theme = useTheme<Theme>();
  const [isBeingHovered, setIsBeingHovered] = useState(false);

  const Underline = () => (
    <motion.div
      style={{
        position: 'absolute',
        right: 0,
        left: 0,
        backgroundColor: theme.palette.primary.main,
        height: '0.25rem',
      }}
      layoutId="underline"
      layout
    ></motion.div>
  );

  const MenuItemVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      x: 0,
      opacity: 1,

      transition: {
        staggerChildren: 0.05,
      },
    },
  };

  const bodyStyle = [
    {
      '&:hover': {
        opacity: 0.6,
      },
    },
  ] as const;

  const linkStyle = [
    {
      outline: 'none',
      cursor: 'pointer',
      color: theme.palette.text.primary,
      textDecoration: 'none',
    },
    {
      '&:hover': {
        opacity: 0.6,
      },
    },
  ] as const;

  return (
    <motion.div
      style={{
        position: 'relative',
        paddingLeft: '2.5rem',
        paddingRight: '2.5rem',
        cursor: 'pointer',
        color: theme.palette.text.primary,
      }}
      onHoverStart={() => setIsBeingHovered(true)}
      onHoverEnd={() => setIsBeingHovered(false)}
    >
      <Box component="span" sx={{ position: 'relative' }}>
        {item.route ? (
          <Typography noWrap component="a" href={item.route} sx={linkStyle}>
            {item.title}
          </Typography>
        ) : (
          <Typography noWrap component="span" sx={bodyStyle}>
            {item.title}
          </Typography>
        )}
        {isBeingHovered && <Underline />}
      </Box>
      {item.subItems && isBeingHovered && (
        <Box maxWidth="max-content">
          <motion.div
            layoutId="menu"
            style={{
              marginTop: '0.25rem',
              position: 'absolute',
              backgroundColor: '#ffffff',
              borderWidth: '1px',
              boxShadow:
                '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
              zIndex: theme.zIndex.tooltip,
            }}
            variants={MenuItemVariants}
            initial="hidden"
            animate="visible"
          >
            {item.subItems.map((item) => (
              <NavbarSubItem key={item.title} item={item} />
            ))}
          </motion.div>
        </Box>
      )}
    </motion.div>
  );
};
