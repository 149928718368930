export const FIRSTNAME_REQUIRED_VALIDATION_ERROR = 'First name is required';
export const FIRSTNAME_MIN_LENGTH_VALIDATION_ERROR =
  'First name must be more than 3 characters';
export const FIRSTNAME_MAX_LENGTH_VALIDATION_ERROR =
  'First name must be less than 32 characters';

export const LASTNAME_REQUIRED_VALIDATION_ERROR = 'Last name is required';
export const LASTNAME_MIN_LENGTH_VALIDATION_ERROR =
  'Last name must be more than 3 characters';
export const LASTNAME_MAX_LENGTH_VALIDATION_ERROR =
  'Last name must be less than 32 characters';

export const EMAIL_REQUIRED_VALIDATION_ERROR = 'Email is required';
export const EMAIL_VALIDATION_ERROR = 'Must be a valid email';

export const COUNTRY_REQUIRED_VALIDATION_ERROR = 'Country is required';

export const JOB_ROLE_REQUIRED_VALIDATION_ERROR = 'Job role is required';

export const YEARS_OF_EXPERIENCE_TYPE_VALIDATION_ERROR = 'A number is required';
export const YEARS_OF_EXPERIENCE_REQUIRED_VALIDATION_ERROR =
  'Years of experience is required';
export const YEARS_OF_EXPERIENCE_MIN_VALUE_VALIDATION_ERROR =
  'Years of experience must be more than 0';
export const YEARS_OF_EXPERIENCE_MAX_VALUE_VALIDATION_ERROR =
  'Years of experience must be less than 50';

export const REGISTRATION_SUCCESSFUL =
  'Thank you for applying to NextSteps.fyi!';
