import React from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  Paper,
  Typography,
} from '@mui/material';

import { PostProps } from './Post.props';

export const Post = ({ post }: PostProps): JSX.Element => {
  return (
    <Paper elevation={12}>
      <CardActionArea component="a" href="#">
        <Card sx={{ display: 'flex' }}>
          <CardContent sx={{ flex: 1 }}>
            <Typography component="h2" variant="h5">
              {post.title}
            </Typography>
            <Typography variant="subtitle1" paragraph>
              {post.description}
            </Typography>
            <Typography variant="subtitle1" color="primary">
              Continue reading...
            </Typography>
          </CardContent>
        </Card>
      </CardActionArea>
    </Paper>
  );
};
