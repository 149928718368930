import React from 'react';
import { Box, CssBaseline, Grid } from '@mui/material';

import { Post } from './Post';
import { blog } from '../../helpers/utils';
import { withHomeLayout } from '../../layouts/home/HomeLayout';

const Blog = (): JSX.Element => {
  return (
    <Box sx={{ mx: 4 }}>
      <CssBaseline />
      <Grid container spacing={2}>
        {blog.posts.map((post) => (
          <Grid key={post.title} item xs={12} sx={{ my: 2 }}>
            <Post post={post} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default withHomeLayout(Blog);
