import { useLocation } from 'react-router';

import Error401 from '../401/401';
import Error404 from '../404/404';
import Error429 from '../429/429';
import Error500 from '../500/500';
import ErrorNoInternet from '../noInternet/NoInternet';
import { ErrorHandlerProps } from './ErrorHandler.props';

const ErrorHandler = ({ children }: ErrorHandlerProps): JSX.Element => {
  const location = useLocation();

  if (location.state && location.state.errorStatusCode) {
    const errorStatusCode = Number(location.state.errorStatusCode);
    // Status codes in the 500 range mean
    // that the server failed for some reason.
    // Status codes in the 400 range mean
    // that the client is doing something unexpected, or unplanned.
    switch (errorStatusCode) {
      case 401: // 401 Unauthorized
        return <Error401 />;
      case 404: // 404 Not Found
        return <Error404 />;
      case 409: // 409 Conflict
        return <>{children}</>;
      case 429: // 429 Too Many Requests
        return <Error429 />;
      case 500: // 500 Internal Server Error
      case 501: // 501 Not Implemented
      case 503: // 503 Service Unavailable
        return <Error500 />;
      case 12163: // 12163 The Internet connection has been lost
        return <ErrorNoInternet />;
      default:
        return <>{children}</>;
    }
  }

  return <>{children}</>;
};

export default ErrorHandler;
