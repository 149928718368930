import React from 'react';
import { CssBaseline, Grid, Typography } from '@mui/material';

import { ArrowLink } from '../../components/ArrowLink/ArrowLink';
import { ReactComponent as HeroPain } from './hero_selection.svg';

export const Pain = (): JSX.Element => {
  return (
    <Grid
      container
      component="main"
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ mx: { md: 0, lg: 8 }, my: 4, width: 'auto' }}
    >
      <CssBaseline />
      <Grid item xs={12} sm={7} md={6} lg={5}>
        <Grid container component="section" rowSpacing={1}>
          <Grid item component="header">
            <Typography component="h1" variant="h2">
              We make changing jobs effortless and efficient
            </Typography>
          </Grid>
          <Grid item component="div">
            <Typography variant="body1">
              Finding a good job is not easy. Before working with us, many
              candidates spent months sending out resumes without receiving a
              single response. Lack of transparency and salary benchmarks, poor
              preparation materials, and challenges with relocation are good
              excuses to not take risks. With us, changing companies or even
              countries can be seamless, take less time, and be more transparent
              for you.
            </Typography>
          </Grid>
          <Grid item component="footer">
            <div>
              <ArrowLink
                variant="secondary"
                title="Start now"
                route="/registration-form"
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={5} md={6} lg={7}>
        <HeroPain />
      </Grid>
    </Grid>
  );
};
