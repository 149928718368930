import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import {
  Box,
  Card,
  CardContent,
  Fab,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@mui/material';

import { ExperienceStepProps } from './ExperienceStep.props';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icons } from '../../../helpers/icons';

export const ExperienceStep = ({
  parentPrefix,
  control,
  errors,
}: ExperienceStepProps): JSX.Element => {
  const { fields, append, remove } = useFieldArray({
    name: `experience.companies`,
    control,
  });

  const handleAddField = () => {
    append({
      title: '',
      positions: [
        {
          title: '',
          startYear: '',
          endYear: '',
          achievements: [{ description: '', subAchievements: [] }],
        },
      ],
    });
  };

  const handleRemoveField = (idx: number) => {
    remove(idx);
  };

  const buildFields = () => {
    return fields.map((field, idx) => (
      <Card key={field.id}>
        <CardContent>
          <Box
            sx={{
              display: 'grid',
              gridAutoFlow: 'row',
              alignItems: 'center',
              justifyItems: 'stretch',
              rowGap: 2,
            }}
          >
            <Box
              sx={{
                display: 'grid',
                gridAutoFlow: 'column',
                gridTemplateColumns: '35px 35px auto',
                alignItems: 'center',
                justifyItems: 'stretch',
                columnGap: 1,
              }}
            >
              <Fab
                disabled={10 === fields.length}
                onClick={handleAddField}
                color="secondary"
                size="small"
              >
                <FontAwesomeIcon icon={icons.faPlus} size="1x" />
              </Fab>
              <Fab
                disabled={1 === fields.length}
                onClick={() => handleRemoveField(idx)}
                color="secondary"
                size="small"
              >
                <FontAwesomeIcon icon={icons.faMinus} size="1x" />
              </Fab>
              <FormControl sx={{ mt: 2 }} fullWidth>
                <InputLabel
                  htmlFor={`experience.companies.${idx}.title`}
                  error={!!errors.experience?.companies?.[idx]?.title}
                >
                  Company
                </InputLabel>
                <Controller
                  name={`experience.companies.${idx}.title`}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <OutlinedInput
                      {...field}
                      id={`experience.companies.${idx}.title`}
                      error={!!errors.experience?.companies?.[idx]?.title}
                      label="Company"
                    />
                  )}
                />
                <FormHelperText
                  id={`experience.companies.${idx}.title-outlined-input-helper-text`}
                  error={!!errors.experience?.companies?.[idx]?.title}
                >
                  {errors.experience?.companies?.[idx]?.title
                    ? errors.experience?.companies?.[idx]?.title?.message
                    : '\u00a0'}
                </FormHelperText>
              </FormControl>
            </Box>
            <FormControl fullWidth>
              <InputLabel
                htmlFor={`experience.companies.${idx}.link-outlined-input`}
                error={!!errors.experience?.companies?.[idx]?.link}
              >
                Link
              </InputLabel>
              <Controller
                name={`experience.companies.${idx}.link`}
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    id={`experience.companies.${idx}.link-outlined-input`}
                    error={!!errors.experience?.companies?.[idx]?.link}
                    label="Link"
                  />
                )}
              />
              <FormHelperText
                id={`experience.companies.${idx}.link-outlined-input-helper-text`}
                error={!!errors.experience?.companies?.[idx]?.link}
              >
                {errors.experience?.companies?.[idx]?.link
                  ? errors.experience?.companies?.[idx]?.link?.message
                  : '\u00a0'}
              </FormHelperText>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel
                htmlFor={`experience.companies.${idx}.duration-outlined-input`}
                error={!!errors.experience?.companies?.[idx]?.duration}
              >
                Duration
              </InputLabel>
              <Controller
                name={`experience.companies.${idx}.duration`}
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    id={`experience.companies.${idx}.duration-outlined-input`}
                    error={!!errors.experience?.companies?.[idx]?.duration}
                    label="Duration"
                  />
                )}
              />
              <FormHelperText
                id={`experience.companies.${idx}.duration-outlined-input-helper-text`}
                error={!!errors.experience?.companies?.[idx]?.duration}
              >
                {errors.experience?.companies?.[idx]?.duration
                  ? errors.experience?.companies?.[idx]?.duration?.message
                  : '\u00a0'}
              </FormHelperText>
            </FormControl>
          </Box>
        </CardContent>
      </Card>
    ));
  };

  return (
    <Box component="form" noValidate>
      <FormControl sx={{ mt: 2 }} fullWidth>
        <InputLabel
          htmlFor="years-of-experience-outlined-input"
          error={!!errors.experience?.yearsOfExperience}
        >
          Years Of Experience
        </InputLabel>
        <Controller
          name="experience.yearsOfExperience"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <OutlinedInput
              {...field}
              id="years-of-experience-outlined-input"
              label="Years Of Experience"
              autoFocus
              error={!!errors.experience?.yearsOfExperience}
            />
          )}
        />
        <FormHelperText
          id="years-of-experience-outlined-input-helper-text"
          error={!!errors.experience?.yearsOfExperience}
        >
          {errors.experience?.yearsOfExperience
            ? errors.experience?.yearsOfExperience?.message
            : '\u00a0'}
        </FormHelperText>
      </FormControl>
      {buildFields()}
    </Box>
  );
};
