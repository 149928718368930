import React, { FunctionComponent, useRef } from 'react';
import {
  Alert,
  AlertTitle,
  Box,
  CssBaseline,
  Fab,
  LinearProgress,
  Snackbar,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { icons } from '../../helpers/icons';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import * as registrationActions from '../../features/registration/registrationSlice';
import * as userActions from '../../features/user/userSlice';
import Header from './Header';
import Footer from './Footer';
import ScrollTop from './ScrollTop';
import { HomeLandingProps } from './HomeLayout.props';

const HomeLayout = ({ children }: HomeLandingProps): JSX.Element => {
  const bodyRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const rootState = useAppSelector((state) => state);

  const cleanError = () => {
    if (rootState.registration.error) {
      dispatch(registrationActions.cleanError());
    } else if (rootState.user.error) {
      dispatch(userActions.cleanError());
    }
  };

  const getError = () => {
    if (rootState.registration.error) {
      return rootState.registration.error;
    } else if (rootState.user.error) {
      return rootState.user.error;
    } else {
      return '';
    }
  };

  const cleanMessage = () => {
    if (rootState.registration.message) {
      dispatch(registrationActions.cleanMessage());
    } else if (rootState.user.message) {
      dispatch(userActions.cleanMessage());
    }
    // dispatch(cleanMessage());
  };

  const getMessage = () => {
    if (rootState.registration.message) {
      return rootState.registration.message;
    } else if (rootState.user.message) {
      return rootState.user.message;
    } else {
      return '';
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <Header />
      <div id="back-to-top-anchor" />
      {(rootState.registration.loading || rootState.user.loading) && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        open={!!rootState.registration.error || !!rootState.user.error}
        onClose={cleanError}
      >
        <Alert onClose={cleanError} severity="error" sx={{ width: '100%' }}>
          <AlertTitle>Error</AlertTitle>
          {getError()}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        open={!!rootState.registration.message || !!rootState.user.message}
        onClose={cleanMessage}
      >
        <Alert onClose={cleanMessage} severity="success" sx={{ width: '100%' }}>
          <AlertTitle>Success</AlertTitle>
          {getMessage()}
        </Alert>
      </Snackbar>
      <main ref={bodyRef} tabIndex={0} role="main">
        {children}
      </main>
      <Footer />
      <ScrollTop>
        <Fab size="small" aria-label="scroll back to top">
          <FontAwesomeIcon icon={icons.faChevronUp} />
        </Fab>
      </ScrollTop>
    </Box>
  );
};

export const withHomeLayout = <T extends Record<string, unknown>>(
  Component: FunctionComponent<T>,
) => {
  return function withLayoutComponent(props: T): JSX.Element {
    return (
      <HomeLayout>
        <CssBaseline />
        <Component {...props} />
      </HomeLayout>
    );
  };
};
