import * as React from 'react';
import { SvgIcon, Theme, Typography, useTheme } from '@mui/material';

import { ArrowLinkProps } from './ArrowLink.props';

const arrowSwgStyle = {
  width: '10px',
  height: '10px',
  position: 'relative',
  top: '1px',

  marginLeft: '5px',
  strokeWidth: '2px',
  fill: 'none',
  stroke: 'currentColor',
} as const;

export const ArrowLink = ({
  route,
  title,
  variant,
}: ArrowLinkProps): JSX.Element => {
  const theme = useTheme<Theme>();
  const linkStyle = [
    {
      outline: 'none',
      padding: '3px 16px 6px',
      display: 'inline-block',
      cursor: 'pointer',
      transition: '150ms cubic-bezier(0.215, 0.61, 0.355, 1)',
      transitionProperty: 'backgroundColor,opacity',

      color: theme.palette.background.default,
      textDecoration: 'none',
      borderRadius: 4,
      backgroundColor: theme.palette.primary.main,
    },
    {
      '.hoverArrowLinePath': {
        opacity: 0,
      },
    },
    {
      '&:hover .hoverArrowLinePath': {
        opacity: 1,
      },
    },
    {
      '&:hover .hoverArrowTipPath': {
        transition: 'transform 150ms cubic-bezier(0.215, 0.61, 0.355, 1)',
        transform: 'translateX(3px)',
      },
    },
  ] as const;

  return (
    <Typography noWrap component="a" href={route} sx={linkStyle}>
      <Typography noWrap component="span">
        {title}
      </Typography>
      <SvgIcon aria-hidden="true" viewBox="0 0 10 10" sx={arrowSwgStyle}>
        <g fillRule="evenodd">
          <path className="hoverArrowLinePath" d="M0 5h7" />
          <path className="hoverArrowTipPath" d="M1 1l4 4-4 4" />
        </g>
      </SvgIcon>
    </Typography>
  );
};
