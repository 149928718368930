import React from 'react';
import { CssBaseline, Grid, Typography } from '@mui/material';

import { ArrowLink } from '../../components/ArrowLink/ArrowLink';
import { ReactComponent as HeroTeam } from './hero_team.svg';

export const Team = (): JSX.Element => {
  return (
    <Grid
      container
      component="main"
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ mx: { md: 0, lg: 8 }, my: 4, width: 'auto' }}
    >
      <CssBaseline />
      <Grid item xs={12} sm={5} md={6} lg={7}>
        <HeroTeam />
      </Grid>
      <Grid item xs={12} sm={7} md={6} lg={5}>
        <Grid container component="section" rowSpacing={1}>
          <Grid item component="header">
            <Typography component="h1" variant="h2">
              Our team
            </Typography>
          </Grid>
          <Grid item component="div">
            <Typography variant="body1">
              We are engineering and DS leads from FAANG. We know your problems
              firsthand and have a lot of experience interviewing and reviewing
              candidates. We want to change the employment process and offer you
              unprecedented support.
            </Typography>
          </Grid>
          <Grid item component="footer">
            <div>
              <ArrowLink
                variant="secondary"
                title="Start now"
                route="/registration-form"
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
