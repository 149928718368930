export const FIRSTNAME_REQUIRED_VALIDATION_ERROR = 'First name is required';
export const FIRSTNAME_MIN_LENGTH_VALIDATION_ERROR =
  'First name must be more than 3 characters';
export const FIRSTNAME_MAX_LENGTH_VALIDATION_ERROR =
  'First name must be less than 32 characters';

export const LASTNAME_REQUIRED_VALIDATION_ERROR = 'Last name is required';
export const LASTNAME_MIN_LENGTH_VALIDATION_ERROR =
  'Last name must be more than 3 characters';
export const LASTNAME_MAX_LENGTH_VALIDATION_ERROR =
  'Last name must be less than 32 characters';

export const EMAIL_REQUIRED_VALIDATION_ERROR = 'Email is required';
export const EMAIL_VALIDATION_ERROR = 'Must be a valid email';

export const MOBILE_REQUIRED_VALIDATION_ERROR = 'Mobile number is required';
export const MOBILE_VALIDATION_ERROR = 'Mobile number is not valid';

export const LINKEDIN_REQUIRED_VALIDATION_ERROR = 'LinkedIn page is required';
export const LINKEDIN_VALIDATION_ERROR = 'LinkedIn page url is not valid';

export const LOCATION_REQUIRED_VALIDATION_ERROR = 'Location is required';

export const DESCRIPTION_INFO_REQUIRED_VALIDATION_ERROR = '';

export const EDUCATION_UNIVERSITY_TITLE_REQUIRED_VALIDATION_ERROR =
  'Title is a required field';

export const EXPERIENCE_COMPANY_TITLE_REQUIRED_VALIDATION_ERROR =
  'Title is a required field';

export const KNOWLEDGE_SKILL_TITLE_REQUIRED_VALIDATION_ERROR =
  'Skill is a required field';
