import {
  alpha,
  createTheme,
  responsiveFontSizes,
  ThemeOptions,
} from '@mui/material/styles';

export const themeOptions: ThemeOptions = {
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: 'transparent',
        },
      },
    },
    // MuiButton: {
    //   styleOverrides: {
    //     root: {
    //       background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    //       border: 0,
    //       borderRadius: 3,
    //       boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    //       color: 'white',
    //       height: 48,
    //       padding: '0 30px',
    //     },
    //   },
    // },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#ff4500',
    },
    secondary: {
      main: '#1e90ff',
    },
    background: {
      default: '#FFF',
      paper: '#FFF',
    },
    text: {
      primary: '#0A2540',
      secondary: '#425466',
    },
    action: {
      active: alpha('#001E3C', 0.8),
    },
    info: {
      main: '#88ADD2',
    },
    success: {
      main: '#009688',
    },
  },
  typography: {
    fontFamily: ['Lato'].join(','),
    h1: {
      fontWeight: 500,
    },
    h2: {
      fontWeight: 400,
    },
  },
  shape: {
    borderRadius: 4,
  },
  spacing: 8,
};

let theme = createTheme(themeOptions);

theme = responsiveFontSizes(theme);

export default theme;
