import { createSlice } from '@reduxjs/toolkit';

import { sortItemsWithOther } from '../../helpers/utils';
import {
  createProfessionalArea,
  getProfessionalAreas,
} from './professionalAreaActions';
import {
  IProfessionalArea,
  IProfessionalAreaState,
} from '../../interfaces/professional-area.interface';

const initialState: IProfessionalAreaState = {
  message: null,
  loading: false,
  error: null,
  success: false,
  entities: [],
};

const professionalAreaSlice = createSlice({
  name: 'professionalArea',
  initialState,
  reducers: {
    cleanError: (state) => {
      state.error = null;
    },
    cleanMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createProfessionalArea.pending, (state) => {
      state.error = null;
      state.loading = true;
      state.success = false;
    });
    builder.addCase(createProfessionalArea.fulfilled, (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.success = true;
    });
    builder.addCase(createProfessionalArea.rejected, (state, { payload }) => {
      state.error = payload as string;
      state.loading = false;
      state.success = false;
    });
    builder.addCase(getProfessionalAreas.pending, (state) => {
      state.error = null;
      state.loading = true;
      state.success = false;
    });
    builder.addCase(getProfessionalAreas.fulfilled, (state, { payload }) => {
      state.entities = sortItemsWithOther(payload) as IProfessionalArea[];
      state.loading = false;
      state.success = true;
    });
    builder.addCase(getProfessionalAreas.rejected, (state, { payload }) => {
      state.error = payload as string;
      state.loading = false;
      state.success = false;
    });
  },
});

export const { cleanError, cleanMessage } = professionalAreaSlice.actions;

export default professionalAreaSlice.reducer;
