import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Button,
  Checkbox,
  Container,
  CssBaseline,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Snackbar,
  Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { icons } from '../../helpers/icons';
import { IUser } from '../../interfaces/user.interface';
import { signUp } from '../../features/user/userActions';
import { cleanError } from '../../features/user/userSlice';
import { withHomeLayout } from '../../layouts/home/HomeLayout';
import { userValidationSchema } from '../../features/user/userValidations';

const EyeIcon = icons.faEye;
const EyeSlashIcon = icons.faEyeSlash;
const UserPlusIcon = icons.faUserPlus;

const SignUp = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { loading, error } = useAppSelector((state) => state.user);
  const [showPassword, setShowPassword] = useState<Boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IUser>({
    resolver: yupResolver(userValidationSchema),
  });

  useEffect(() => {
    dispatch(cleanError());
  }, []);

  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordMouseDown = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const onSubmit = (formData: IUser) => {
    dispatch(signUp({ location, navigate, user: formData }));
    navigate('/signin', { state: {} });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        open={!!error}
        onClose={() => dispatch(cleanError())}
      >
        <Alert
          onClose={() => dispatch(cleanError())}
          severity="error"
          sx={{ width: '100%' }}
        >
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>
      </Snackbar>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <FontAwesomeIcon icon={UserPlusIcon} />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
        >
          <FormControl sx={{ mt: 2 }} fullWidth>
            <InputLabel
              htmlFor="username-outlined-input"
              error={!!errors.username}
            >
              Username
            </InputLabel>
            <Controller
              name="username"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <OutlinedInput
                  {...field}
                  id="username-outlined-input"
                  label="Username"
                  autoComplete="email"
                  autoFocus
                  error={!!errors.username}
                />
              )}
            />
            <FormHelperText
              id="username-outlined-input-helper-text"
              error={!!errors.username}
            >
              {errors.username ? errors.username.message : '\u00a0'}
            </FormHelperText>
          </FormControl>
          <FormControl sx={{ mt: 2 }} fullWidth>
            <InputLabel
              htmlFor="password-outlined-input"
              error={!!errors.password}
            >
              Password
            </InputLabel>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <OutlinedInput
                  {...field}
                  id="password-outlined-input"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPasswordClick}
                        onMouseDown={handlePasswordMouseDown}
                        sx={{ width: 40 }}
                      >
                        {showPassword ? (
                          <FontAwesomeIcon icon={EyeSlashIcon} />
                        ) : (
                          <FontAwesomeIcon icon={EyeIcon} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  autoComplete="email"
                  error={!!errors.password}
                />
              )}
            />
            <FormHelperText
              id="password-outlined-input-helper-text"
              error={!!errors.password}
            >
              {errors.password ? errors.password.message : '\u00a0'}
            </FormHelperText>
          </FormControl>
          <FormControlLabel
            control={<Checkbox value="allowExtraEmails" color="primary" />}
            label="I want to receive inspiration, marketing promotions and updates via email."
          />
          <Button
            type="submit"
            fullWidth
            disabled={loading}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link variant="body2" component={RouterLink} to="/signin">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default withHomeLayout(SignUp);
