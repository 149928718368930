import React, { useEffect } from 'react';

import { withHomeLayout } from '../../layouts/home/HomeLayout';

const PrivacyPolicy = (): JSX.Element => {
  const divProps = {
    name: 'termly-embed',
    'data-id': 'c32e9f17-8afe-4488-873a-4480033cb101',
    'data-type': 'iframe',
  };
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://app.termly.io/embed-policy.min.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return <div {...divProps}></div>;
};

export default withHomeLayout(PrivacyPolicy);
