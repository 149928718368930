import React from 'react';
import { useNavigate } from 'react-router';

import { icons } from '../../helpers/icons';
import ErrorContainer from '../../components/ErrorContainer/ErrorContainer';
import { withHomeLayout } from '../../layouts/home/HomeLayout';

const ErrorNoInternet = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <ErrorContainer
      buttonTitle="Thanks"
      errorIcon={icons.faWifiSlash}
      description="Checking the network cables, modem and router. Reconnecting to Wi-Fi."
      routeHandler={() => navigate('/')}
      title="No Internet!"
    />
  );
};

export default withHomeLayout(ErrorNoInternet);
