import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from '@mui/material';

import { ContactStepProps } from './ContactStep.props';

export const ContactStep = ({
  parentPrefix,
  control,
  errors,
}: ContactStepProps): JSX.Element => {
  return (
    <Box component="form" noValidate>
      <FormControl sx={{ mt: 2 }} fullWidth>
        <InputLabel
          htmlFor="first-name-outlined-input"
          error={!!errors.contact?.firstName}
        >
          First name
        </InputLabel>
        <Controller
          name="contact.firstName"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <OutlinedInput
              {...field}
              id="first-name-outlined-input"
              label="First name"
              autoFocus
              error={!!errors.contact?.firstName}
            />
          )}
        />
        <FormHelperText
          id="first-name-outlined-input-helper-text"
          error={!!errors.contact?.firstName}
        >
          {errors.contact?.firstName
            ? errors.contact?.firstName?.message
            : '\u00a0'}
        </FormHelperText>
      </FormControl>
      <FormControl sx={{ mt: 2 }} fullWidth>
        <InputLabel
          htmlFor="last-name-outlined-input"
          error={!!errors.contact?.lastName}
        >
          Last name
        </InputLabel>
        <Controller
          name="contact.lastName"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <OutlinedInput
              {...field}
              id="lastname-outlined-input"
              label="Last name"
              error={!!errors.contact?.lastName}
            />
          )}
        />
        <FormHelperText
          id="lastname-outlined-input-helper-text"
          error={!!errors.contact?.lastName}
        >
          {errors.contact?.lastName
            ? errors.contact?.lastName?.message
            : '\u00a0'}
        </FormHelperText>
      </FormControl>
      <FormControl sx={{ mt: 2 }} fullWidth>
        <InputLabel
          htmlFor="email-outlined-input"
          error={!!errors.contact?.email}
        >
          Email
        </InputLabel>
        <Controller
          name="contact.email"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <OutlinedInput
              {...field}
              id="email-outlined-input"
              label="Email"
              autoComplete="email"
              error={!!errors.contact?.email}
            />
          )}
        />
        <FormHelperText
          id="email-outlined-input-helper-text"
          error={!!errors.contact?.email}
        >
          {errors.contact?.email ? errors.contact?.email?.message : '\u00a0'}
        </FormHelperText>
      </FormControl>
      <FormControl sx={{ mt: 2 }} fullWidth>
        <InputLabel
          htmlFor="mobile-outlined-input"
          error={!!errors.contact?.mobile}
        >
          Mobile
        </InputLabel>
        <Controller
          name="contact.mobile"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <OutlinedInput
              {...field}
              id="mobile-outlined-input"
              label="Mobile"
              autoComplete="tel"
              error={!!errors.contact?.mobile}
            />
          )}
        />
        <FormHelperText
          id="mobile-outlined-input-helper-text"
          error={!!errors.contact?.mobile}
        >
          {errors.contact?.mobile ? errors.contact?.mobile?.message : '\u00a0'}
        </FormHelperText>
      </FormControl>
      <FormControl sx={{ mt: 2 }} fullWidth>
        <InputLabel
          htmlFor="linkedin-outlined-input"
          error={!!errors.contact?.linkedin}
        >
          LinkedIn
        </InputLabel>
        <Controller
          name="contact.linkedin"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <OutlinedInput
              {...field}
              id="linkedin-outlined-input"
              label="LinkedIn"
              autoComplete="url"
              error={!!errors.contact?.linkedin}
            />
          )}
        />
        <FormHelperText
          id="linkedIn-outlined-input-helper-text"
          error={!!errors.contact?.linkedin}
        >
          {errors.contact?.linkedin
            ? errors.contact?.linkedin?.message
            : '\u00a0'}
        </FormHelperText>
      </FormControl>
      <FormControl sx={{ mt: 2 }} fullWidth>
        <InputLabel
          htmlFor="location-outlined-input"
          error={!!errors.contact?.location}
        >
          Location
        </InputLabel>
        <Controller
          name="contact.location"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <OutlinedInput
              {...field}
              id="location-outlined-input"
              label="Location"
              autoComplete="url"
              error={!!errors.contact?.location}
            />
          )}
        />
        <FormHelperText
          id="location-outlined-input-helper-text"
          error={!!errors.contact?.location}
        >
          {errors.contact?.location
            ? errors.contact?.location?.message
            : '\u00a0'}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};
