import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import API from '../../helpers/api';
import { axiosErrorHandler } from '../../helpers/utils';
import { ICreateResume } from '../../interfaces/resume.interface';

export const createResume = createAsyncThunk<
  string,
  ICreateResume,
  { rejectValue: unknown }
>(
  'resume/create',
  async (
    { location, navigate, application }: ICreateResume,
    { rejectWithValue },
  ) => {
    try {
      const { data } = await API.resume.create(application);

      const wnd = window.open('about:blank', '', '_blank');

      wnd && wnd.document.write(data.html);
      wnd && wnd.document.close();

      return data.message;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;

        return rejectWithValue(
          axiosErrorHandler(axiosError, location, navigate),
        );
      } else {
        return rejectWithValue(error);
      }
    }
  },
);
