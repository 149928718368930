import { createSlice } from '@reduxjs/toolkit';

import { sortItemsWithOther } from '../../helpers/utils';
import {
  ICareerGoal,
  ICareerGoalState,
} from '../../interfaces/career-goal.interface';
import { createCareerGoal, getCareerGoals } from './careerGoalActions';

const initialState: ICareerGoalState = {
  message: null,
  loading: false,
  error: null,
  success: false,
  entities: [],
};

const careerGoalSlice = createSlice({
  name: 'careerGoal',
  initialState,
  reducers: {
    cleanError: (state) => {
      state.error = null;
    },
    cleanMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createCareerGoal.pending, (state) => {
      state.error = null;
      state.loading = true;
      state.success = false;
    });
    builder.addCase(createCareerGoal.fulfilled, (state, { payload }) => {
      state.message = payload.message;
      state.loading = false;
      state.success = true;
    });
    builder.addCase(createCareerGoal.rejected, (state, { payload }) => {
      state.error = payload as string;
      state.loading = false;
      state.success = false;
    });
    builder.addCase(getCareerGoals.pending, (state) => {
      state.error = null;
      state.loading = true;
      state.success = false;
    });
    builder.addCase(getCareerGoals.fulfilled, (state, { payload }) => {
      state.entities = sortItemsWithOther(payload) as ICareerGoal[];
      state.loading = false;
      state.success = true;
    });
    builder.addCase(getCareerGoals.rejected, (state, { payload }) => {
      state.error = payload as string;
      state.loading = false;
      state.success = false;
    });
  },
});

export const { cleanError, cleanMessage } = careerGoalSlice.actions;

export default careerGoalSlice.reducer;
