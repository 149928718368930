import React from 'react';
import ReactDOM from 'react-dom/client';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { CssBaseline, ThemeProvider } from '@mui/material';

import { setupStore } from './app/store';
import theme from './theme';
import ErrorHandler from './pages/errorHandler/ErrorHandler';
import App from './App';
import CookiesBanner from './components/CookiesBanner';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const initialState = {};
const store = setupStore(initialState);

root.render(
  <React.StrictMode>
    <CookiesProvider>
      <Provider store={store}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <ErrorHandler>
              <App />
              <CookiesBanner />
            </ErrorHandler>
          </ThemeProvider>
        </BrowserRouter>
      </Provider>
    </CookiesProvider>
  </React.StrictMode>,
);
