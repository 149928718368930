import React, { useEffect } from 'react';

import { withHomeLayout } from '../../layouts/home/HomeLayout';

const TermsAndConditions = (): JSX.Element => {
  const divProps = {
    name: 'termly-embed',
    'data-id': '63f90627-bf55-4746-ab03-f97dc7b41cc9',
    'data-type': 'iframe',
  };
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://app.termly.io/embed-policy.min.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return <div {...divProps}></div>;
};

export default withHomeLayout(TermsAndConditions);
