import React from 'react';
import { Box } from '@mui/material';

import { withHomeLayout } from '../../layouts/home/HomeLayout';

const NetSalaryCalc = (): JSX.Element => {
  return <Box sx={{ mx: 4 }}>Net-Salary-Calc</Box>;
};

export default withHomeLayout(NetSalaryCalc);
