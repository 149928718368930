import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import API from '../../helpers/api';
import { axiosErrorHandler } from '../../helpers/utils';
import {
  ICreateCareerGoalRequest,
  ICreateCareerGoalResponse,
  IGetCareerGoalsRequest,
  IGetCareerGoalsResponse,
} from '../../interfaces/career-goal.interface';

export const createCareerGoal = createAsyncThunk<
  ICreateCareerGoalResponse,
  ICreateCareerGoalRequest,
  { rejectValue: unknown }
>(
  'career-goal/create',
  async (
    { location, navigate, formData }: ICreateCareerGoalRequest,
    { rejectWithValue },
  ) => {
    try {
      const { data } = await API.professionalArea.create(formData);

      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;

        return rejectWithValue(
          axiosErrorHandler(axiosError, location, navigate),
        );
      } else {
        return rejectWithValue(error);
      }
    }
  },
);

export const getCareerGoals = createAsyncThunk<
  IGetCareerGoalsResponse,
  IGetCareerGoalsRequest,
  { rejectValue: unknown }
>(
  'career-goal/get-all',
  async (
    { location, navigate }: IGetCareerGoalsRequest,
    { rejectWithValue },
  ) => {
    try {
      const { data } = await API.careerGoal.get();

      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;

        return rejectWithValue(
          axiosErrorHandler(axiosError, location, navigate),
        );
      } else {
        return rejectWithValue(error);
      }
    }
  },
);
