import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { icons } from '../../helpers/icons';

const iconWrapperStyle = {
  backgroundColor: 'transparent',
  borderRadius: '50%',
  boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 50,
  height: 50,
} as const;

export const Process = (): JSX.Element => {
  return (
    <Box sx={{ mx: { md: 0, lg: 8 }, my: 4 }}>
      <Grid
        id="process"
        container
        component="section"
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: 'auto',
          display: { xs: 'flex', md: 'none' },
        }}
      >
        <Grid item component="header" xs={12} sx={{ mb: 4 }}>
          <Typography component="h1" variant="h2">
            How it works
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={5}>
            <Typography variant="h5" paragraph>
              We do
            </Typography>
          </Grid>
          <Grid container item xs={2} justifyContent="center">
            <Divider orientation="vertical" sx={{ ml: '1px' }} />
          </Grid>
          <Grid item xs={5}>
            <Typography variant="h5" paragraph>
              Your steps
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs sx={{ mb: 2 }}>
            provide companies aligned with your priorities
          </Grid>
          <Divider orientation="vertical" flexItem>
            <Box sx={iconWrapperStyle}>
              <FontAwesomeIcon
                icon={icons.faListCheck}
                style={{ fontSize: 24 }}
              />
            </Box>
          </Divider>
          <Grid item xs>
            choose positions
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs sx={{ mb: 2 }}>
            personalise CV apply and manage communication
          </Grid>
          <Divider orientation="vertical" flexItem>
            <Box sx={iconWrapperStyle}>
              <FontAwesomeIcon
                icon={icons.faEnvelopeOpenText}
                style={{ fontSize: 24 }}
              />
            </Box>
          </Divider>
          <Grid item xs />
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs sx={{ mb: 2 }}>
            provide personalised materials
          </Grid>
          <Divider orientation="vertical" flexItem>
            <Box sx={iconWrapperStyle}>
              <FontAwesomeIcon
                icon={icons.faPhoneVolume}
                style={{ fontSize: 24 }}
              />
            </Box>
          </Divider>
          <Grid item xs>
            take the interview
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs sx={{ mb: 2 }}>
            provide personalised materials
          </Grid>
          <Divider orientation="vertical" flexItem>
            <Box sx={iconWrapperStyle}>
              <FontAwesomeIcon
                icon={icons.faComputer}
                style={{ fontSize: 24 }}
              />
            </Box>
          </Divider>
          <Grid item xs>
            take the interview
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs sx={{ mb: 2 }}>
            provide benchmarks and negotiations advice
          </Grid>
          <Divider orientation="vertical" flexItem>
            <Box sx={iconWrapperStyle}>
              <FontAwesomeIcon
                icon={icons.faCommentsDollar}
                style={{ fontSize: 24 }}
              />
            </Box>
          </Divider>
          <Grid item xs>
            choose offer
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs sx={{ mb: 2 }} />
          <Divider orientation="vertical" flexItem>
            <Box sx={iconWrapperStyle}>
              <FontAwesomeIcon
                icon={icons.faFileSignature}
                style={{ fontSize: 24 }}
              />
            </Box>
          </Divider>
          <Grid item xs />
        </Grid>
      </Grid>
      <Grid
        id="process"
        container
        component="section"
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ my: 2, width: 'auto', display: { xs: 'none', md: 'flex' } }}
      >
        <Grid item component="header" xs={12} sx={{ mb: 4 }}>
          <Typography component="h1" variant="h2">
            How it works
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={1} />
          <Grid
            item
            xs={2}
            sx={{ px: 2, alignSelf: 'flex-end', textAlign: 'center' }}
          >
            <Typography variant="h5" paragraph>
              opportunities
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{ px: 2, alignSelf: 'flex-end', textAlign: 'center' }}
          >
            <Typography variant="h5" paragraph>
              CV and applications
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{ px: 2, alignSelf: 'flex-end', textAlign: 'center' }}
          >
            <Typography variant="h5" paragraph>
              HR screening
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{ px: 2, alignSelf: 'flex-end', textAlign: 'center' }}
          >
            <Typography variant="h5" paragraph>
              on-site interviews
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{ px: 2, alignSelf: 'flex-end', textAlign: 'center' }}
          >
            <Typography variant="h5" paragraph>
              negotiation
            </Typography>
          </Grid>
          <Grid
            item
            xs={1}
            sx={{ px: 2, alignSelf: 'flex-end', textAlign: 'center' }}
          >
            <Typography variant="h5" paragraph>
              job offer
            </Typography>
          </Grid>
          <Grid container item xs={1}>
            <Divider
              sx={{
                alignSelf: 'center',
                justifySelf: 'stretch',
                width: '100%',
                mt: '1px',
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Divider flexItem>
              <Box sx={iconWrapperStyle}>
                <FontAwesomeIcon
                  icon={icons.faListCheck}
                  style={{ fontSize: 24 }}
                />
              </Box>
            </Divider>
          </Grid>
          <Grid item xs={2}>
            <Divider flexItem>
              <Box sx={iconWrapperStyle}>
                <FontAwesomeIcon
                  icon={icons.faEnvelopeOpenText}
                  style={{ fontSize: 24 }}
                />
              </Box>
            </Divider>
          </Grid>
          <Grid item xs={2}>
            <Divider flexItem>
              <Box sx={iconWrapperStyle}>
                <FontAwesomeIcon
                  icon={icons.faPhoneVolume}
                  style={{ fontSize: 24 }}
                />
              </Box>
            </Divider>
          </Grid>
          <Grid item xs={2}>
            <Divider flexItem>
              <Box sx={iconWrapperStyle}>
                <FontAwesomeIcon
                  icon={icons.faComputer}
                  style={{ fontSize: 24 }}
                />
              </Box>
            </Divider>
          </Grid>
          <Grid item xs={2}>
            <Divider flexItem>
              <Box sx={iconWrapperStyle}>
                <FontAwesomeIcon
                  icon={icons.faCommentsDollar}
                  style={{ fontSize: 24 }}
                />
              </Box>
            </Divider>
          </Grid>
          <Grid item xs={1}>
            <Divider textAlign="right" flexItem>
              <Box sx={iconWrapperStyle}>
                <FontAwesomeIcon
                  icon={icons.faFileSignature}
                  style={{ fontSize: 24 }}
                />
              </Box>
            </Divider>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="h5" paragraph sx={{ textAlign: 'center' }}>
              We do
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ px: 2 }}>
            <Typography variant="subtitle1" paragraph>
              provide companies aligned with your priorities
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ px: 2 }}>
            <Typography variant="subtitle1" paragraph>
              personalise CV apply and manage communication
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ px: 2 }}>
            <Typography variant="subtitle1" paragraph>
              provide personalised materials
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ px: 2 }}>
            <Typography variant="subtitle1" paragraph>
              provide personalised materials
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ px: 2 }}>
            <Typography variant="subtitle1" paragraph>
              provide benchmarks and negotiations advice
            </Typography>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={1} sx={{ px: 2 }}>
            <Typography variant="h5" paragraph sx={{ textAlign: 'center' }}>
              Your steps
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ px: 2 }}>
            <Typography variant="subtitle1" paragraph>
              choose positions
            </Typography>
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={2} sx={{ px: 2 }}>
            <Typography variant="subtitle1" paragraph>
              take the interview
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ px: 2 }}>
            <Typography variant="subtitle1" paragraph>
              take the interview
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ px: 2 }}>
            <Typography variant="subtitle1" paragraph>
              choose offer
            </Typography>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Grid>
    </Box>
  );
};
