import { AxiosInstance } from 'axios';

const withConfigInterceptor = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use((config) => {
    if (config.headers) {
      config.headers['Content-Type'] = 'application/json';
    }

    config.timeout = 5000;
    config.timeoutErrorMessage = 'The request timed out.';

    return config;
  });
};

export default withConfigInterceptor;
