import {
  combineReducers,
  configureStore,
  PreloadedState,
} from '@reduxjs/toolkit';
// import logger from 'redux-logger';

import careerGoalReducer from '../features/careerGoal/careerGoalSlice';
import professionalAreaReducer from '../features/professionalArea/professionalAreaSlice';
import registrationReducer from '../features/registration/registrationSlice';
import userReducer from '../features/user/userSlice';

// Create the root reducer independently to obtain the RootState type
const rootReducer = combineReducers({
  careerGoal: careerGoalReducer,
  professionalArea: professionalAreaReducer,
  registration: registrationReducer,
  user: userReducer,
});

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  });
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
