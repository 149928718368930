import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import {
  Box,
  Card,
  CardContent,
  Fab,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { icons } from '../../../helpers/icons';
import { KnowledgeStepProps } from './KnowledgeStep.props';

export const KnowledgeStep = ({
  parentPrefix,
  control,
  errors,
}: KnowledgeStepProps): JSX.Element => {
  const { fields, append, remove } = useFieldArray({
    name: `knowledge.skills`,
    control,
  });

  const handleAddField = () => {
    append({ title: '' });
  };

  const handleRemoveField = (idx: number) => {
    remove(idx);
  };

  const buildFields = () => {
    return fields.map((field, idx) => (
      <Card key={field.id}>
        <CardContent>
          <Box
            sx={{
              display: 'grid',
              gridAutoFlow: 'row',
              alignItems: 'center',
              justifyItems: 'stretch',
              rowGap: 1,
            }}
          >
            <Box
              sx={{
                display: 'grid',
                gridAutoFlow: 'column',
                gridTemplateColumns: '35px 35px auto',
                alignItems: 'center',
                justifyItems: 'stretch',
                columnGap: 1,
              }}
            >
              <Fab
                disabled={10 === fields.length}
                onClick={handleAddField}
                color="secondary"
                size="small"
              >
                <FontAwesomeIcon icon={icons.faPlus} size="1x" />
              </Fab>
              <Fab
                disabled={1 === fields.length}
                onClick={() => handleRemoveField(idx)}
                color="secondary"
                size="small"
              >
                <FontAwesomeIcon icon={icons.faMinus} size="1x" />
              </Fab>
              <FormControl sx={{ mt: 2 }} fullWidth>
                <InputLabel
                  htmlFor={`knowledge.skills.${idx}.title`}
                  error={!!errors.knowledge?.skills?.[idx]?.title}
                >
                  Skill
                </InputLabel>
                <Controller
                  name={`knowledge.skills.${idx}.title`}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <OutlinedInput
                      {...field}
                      id={`knowledge.skills.${idx}.title`}
                      error={!!errors.knowledge?.skills?.[idx]?.title}
                      label="Skill"
                    />
                  )}
                />
                <FormHelperText
                  id={`knowledge.skills.${idx}.title-outlined-input-helper-text`}
                  error={!!errors.knowledge?.skills?.[idx]?.title}
                >
                  {errors.knowledge?.skills?.[idx]?.title
                    ? errors.knowledge?.skills?.[idx]?.title?.message
                    : '\u00a0'}
                </FormHelperText>
              </FormControl>
            </Box>
            <FormControl fullWidth>
              <Controller
                name={`knowledge.skills.${idx}.description`}
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    id={`knowledge.skills.${idx}.description-text-field`}
                    error={!!errors.knowledge?.skills?.[idx]?.description}
                    aria-invalid={
                      !!errors.knowledge?.skills?.[idx]?.description
                    }
                    label="Description"
                    multiline
                    rows={3}
                    color="primary"
                  />
                )}
              />
            </FormControl>
          </Box>
        </CardContent>
      </Card>
    ));
  };

  return (
    <Box component="form" noValidate>
      {buildFields()}
    </Box>
  );
};
