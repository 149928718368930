import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import API from '../../helpers/api';
import { axiosErrorHandler } from '../../helpers/utils';
import {
  ICreateProfessionalArea,
  IGetProfessionalAreas,
  IProfessionalArea,
} from '../../interfaces/professional-area.interface';

export const createProfessionalArea = createAsyncThunk<
  string,
  ICreateProfessionalArea,
  { rejectValue: unknown }
>(
  'professional-area/create',
  async (
    { location, navigate, formData }: ICreateProfessionalArea,
    { rejectWithValue },
  ) => {
    try {
      const { data } = await API.professionalArea.create(formData);

      return data.message;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;

        return rejectWithValue(
          axiosErrorHandler(axiosError, location, navigate),
        );
      } else {
        return rejectWithValue(error);
      }
    }
  },
);

export const getProfessionalAreas = createAsyncThunk<
  IProfessionalArea[],
  IGetProfessionalAreas,
  { rejectValue: unknown }
>(
  'professional-area/get-all',
  async (
    { location, navigate }: IGetProfessionalAreas,
    { rejectWithValue },
  ) => {
    try {
      const { data } = await API.professionalArea.get();

      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;

        return rejectWithValue(
          axiosErrorHandler(axiosError, location, navigate),
        );
      } else {
        return rejectWithValue(error);
      }
    }
  },
);
