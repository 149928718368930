import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useForm, Controller } from 'react-hook-form';
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Container,
  CssBaseline,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { icons } from '../../helpers/icons';
import { IRegistrationForm } from '../../interfaces/registration.interface';
import { createRegistration } from '../../features/registration/registrationActions';
import { getCareerGoals } from '../../features/careerGoal/careerGoalActions';
import { getProfessionalAreas } from '../../features/professionalArea/professionalAreaActions';
import { registrationValidationSchema } from '../../features/registration/registrationValidations';
import { withHomeLayout } from '../../layouts/home/HomeLayout';

const formWrapperStyle = {
  marginTop: 2,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
} as const;

const RegistrationForm = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { loading, success } = useAppSelector((state) => state.registration);
  const { entities: careerGoals } = useAppSelector((state) => state.careerGoal);
  const { entities: professionalAreas } = useAppSelector(
    (state) => state.professionalArea,
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IRegistrationForm>({
    resolver: yupResolver(registrationValidationSchema),
  });

  useEffect(() => {
    dispatch(getCareerGoals({ location, navigate }));
    dispatch(getProfessionalAreas({ location, navigate }));
  }, [dispatch, location, navigate]);

  useEffect(() => {
    if (success) {
      reset();
      navigate('/');
    }
  }, [dispatch, success, reset, navigate]);

  const onSubmit = async (formData: IRegistrationForm) => {
    await dispatch(
      createRegistration({
        location,
        navigate,
        formData,
      }),
    );
  };

  return (
    <Box>
      <CssBaseline />
      <Container component="main" maxWidth="xs">
        <Box sx={formWrapperStyle}>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <FontAwesomeIcon icon={icons.faAddressCard} />
          </Avatar>
          <Typography component="h1" variant="h5">
            Application Form
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{ mt: 1 }}
          >
            <FormControl sx={{ mt: 2 }} fullWidth>
              <InputLabel
                htmlFor="first-name-outlined-input"
                error={!!errors.firstName}
              >
                First name
              </InputLabel>
              <Controller
                name="firstName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    id="first-name-outlined-input"
                    label="First name"
                    autoFocus
                    error={!!errors.firstName}
                  />
                )}
              />
              <FormHelperText
                id="first-name-outlined-input-helper-text"
                error={!!errors.firstName}
              >
                {errors.firstName ? errors.firstName.message : '\u00a0'}
              </FormHelperText>
            </FormControl>
            <FormControl sx={{ mt: 2 }} fullWidth>
              <InputLabel
                htmlFor="last-name-outlined-input"
                error={!!errors.lastName}
              >
                Last name
              </InputLabel>
              <Controller
                name="lastName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    id="last-name-outlined-input"
                    label="Last name"
                    error={!!errors.lastName}
                  />
                )}
              />
              <FormHelperText
                id="last-name-outlined-input-helper-text"
                error={!!errors.lastName}
              >
                {errors.lastName ? errors.lastName.message : '\u00a0'}
              </FormHelperText>
            </FormControl>
            <FormControl sx={{ mt: 2 }} fullWidth>
              <InputLabel htmlFor="email-outlined-input" error={!!errors.email}>
                Email
              </InputLabel>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    id="email-outlined-input"
                    label="Email"
                    autoComplete="email"
                    error={!!errors.email}
                  />
                )}
              />
              <FormHelperText
                id="email-outlined-input-helper-text"
                error={!!errors.email}
              >
                {errors.email ? errors.email.message : '\u00a0'}
              </FormHelperText>
            </FormControl>
            <FormControl sx={{ mt: 2 }} fullWidth>
              <InputLabel
                id="professional-area-select-label"
                error={!!errors.professionalAreaId}
              >
                What is your professional area?
              </InputLabel>
              <Controller
                name="professionalAreaId"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    id="professional-area-select"
                    label="What is your professional area?"
                    labelId="professional-area-select-label"
                    error={!!errors.professionalAreaId}
                    data-testid="professional-area-select"
                  >
                    {professionalAreas &&
                      professionalAreas.map(({ id, title }) => (
                        <MenuItem key={id} value={id}>
                          {title}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
              <FormHelperText
                id="professional-area-select-helper-text"
                error={!!errors.professionalAreaId}
              >
                {errors.professionalAreaId
                  ? errors.professionalAreaId.message
                  : '\u00a0'}
              </FormHelperText>
            </FormControl>
            <FormControl sx={{ mt: 2 }} fullWidth>
              <InputLabel
                id="career-goal-select-label"
                error={!!errors.careerGoalId}
              >
                What is your career goal?
              </InputLabel>
              <Controller
                name="careerGoalId"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    id="career-goal-select"
                    label="What is your career goal?"
                    labelId="career-goal-select-label"
                    error={!!errors.careerGoalId}
                    data-testid="career-goal-select"
                  >
                    {careerGoals &&
                      careerGoals.map(({ id, title }) => (
                        <MenuItem key={id} value={id}>
                          {title}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
              <FormHelperText
                id="career-goal-select-helper-text"
                error={!!errors.careerGoalId}
              >
                {errors.careerGoalId ? errors.careerGoalId.message : '\u00a0'}
              </FormHelperText>
            </FormControl>
            <FormControl sx={{ mt: 2 }} fullWidth>
              <Controller
                name="yearsOfExperience"
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <ButtonGroup
                    variant="text"
                    aria-label="years of experience button group"
                  >
                    <Typography
                      component="h5"
                      sx={{ alignSelf: 'center', mr: 2 }}
                    >
                      Years of experience:
                    </Typography>
                    <IconButton
                      aria-label="decrement years of experience"
                      onClick={() => field.onChange(Number(field.value) - 1)}
                      disabled={
                        !!errors.yearsOfExperience ||
                        isNaN(field.value) ||
                        0 >= field.value
                      }
                      sx={{ width: 50 }}
                    >
                      <FontAwesomeIcon icon={icons.faMinus} />
                    </IconButton>
                    <OutlinedInput
                      {...field}
                      id="years-of-experience-outlined-input"
                      error={!!errors.yearsOfExperience}
                      sx={{ width: 50 }}
                      data-testid="years-of-experience-outlined-input"
                    />
                    <IconButton
                      aria-label="increment years of experience"
                      onClick={() => field.onChange(1 + Number(field.value))}
                      disabled={
                        !!errors.yearsOfExperience ||
                        isNaN(field.value) ||
                        50 <= field.value
                      }
                      sx={{ width: 50 }}
                    >
                      <FontAwesomeIcon icon={icons.faPlus} />
                    </IconButton>
                  </ButtonGroup>
                )}
              />
              <FormHelperText
                id="yearsOfExperience-outlined-input-helper-text"
                error={!!errors.yearsOfExperience}
              >
                {errors.yearsOfExperience
                  ? errors.yearsOfExperience.message
                  : '\u00a0'}
              </FormHelperText>
            </FormControl>
            <Button
              type="submit"
              fullWidth
              disabled={loading}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default withHomeLayout(RegistrationForm);
