import { createSlice } from '@reduxjs/toolkit';

import { IRegistrationState } from '../../interfaces/registration.interface';
import { createRegistration } from './registrationActions';

const initialState: IRegistrationState = {
  error: null,
  loading: false,
  message: null,
  success: false,
};

const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    cleanError: (state) => {
      state.error = null;
    },
    cleanMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createRegistration.pending, (state) => {
      state.error = null;
      state.loading = true;
      state.success = false;
    });
    builder.addCase(createRegistration.fulfilled, (state, { payload }) => {
      state.message = payload.message;
      state.loading = false;
      state.success = true;
    });
    builder.addCase(createRegistration.rejected, (state, { payload }) => {
      state.error = payload as string;
      state.loading = false;
      state.success = false;
    });
  },
});

export const { cleanError, cleanMessage } = registrationSlice.actions;

export default registrationSlice.reducer;
