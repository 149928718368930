import React from 'react';
import { Box, Theme, Typography, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { icons } from '../../helpers/icons';
import { NavbarSubItemProps } from './NavbarSubItem.props';

const SubItemVariants = {
  hidden: {
    x: -20,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
  },
};

export const NavbarSubItem = ({ item }: NavbarSubItemProps) => {
  const theme = useTheme<Theme>();
  const linkStyle = [
    {
      px: 1,
      display: 'flex',
      alignItems: 'flex-start',
      gap: '1rem',
      outline: 'none',
      cursor: 'pointer',
      color: theme.palette.text.primary,
      textDecoration: 'none',
    },
    {
      '&:hover': {
        opacity: 0.6,
      },
    },
  ] as const;

  return (
    <motion.div
      style={{
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
        minWidth: 'max-content',
        cursor: 'pointer',
      }}
      layout
      variants={SubItemVariants}
    >
      <Typography noWrap component="a" href={item.route} sx={linkStyle}>
        <FontAwesomeIcon
          icon={item.icon ? item.icon : icons.faHome}
          style={{ marginTop: '0.25rem' }}
        />
        <Box>
          <Typography
            paragraph={true}
            sx={{
              color: (theme) => theme.palette.text.primary,
              fontWeight: 700,
              m: 0,
            }}
          >
            {item.title}
          </Typography>
          {item.subTitle && (
            <Box
              component="span"
              sx={{
                color: (theme) => theme.palette.text.primary,
                fontSize: '0.875rem',
                lineHeight: '1.25rem',
                fontWeight: 700,
                opacity: 0.6,
              }}
            >
              {item.subTitle}
            </Box>
          )}
        </Box>
      </Typography>
    </motion.div>
  );
};
