import * as yup from 'yup';

import {
  FIRSTNAME_REQUIRED_VALIDATION_ERROR,
  FIRSTNAME_MIN_LENGTH_VALIDATION_ERROR,
  FIRSTNAME_MAX_LENGTH_VALIDATION_ERROR,
  LASTNAME_REQUIRED_VALIDATION_ERROR,
  LASTNAME_MIN_LENGTH_VALIDATION_ERROR,
  LASTNAME_MAX_LENGTH_VALIDATION_ERROR,
  EMAIL_REQUIRED_VALIDATION_ERROR,
  EMAIL_VALIDATION_ERROR,
  MOBILE_REQUIRED_VALIDATION_ERROR,
  MOBILE_VALIDATION_ERROR,
  LINKEDIN_REQUIRED_VALIDATION_ERROR,
  LINKEDIN_VALIDATION_ERROR,
  LOCATION_REQUIRED_VALIDATION_ERROR,
  DESCRIPTION_INFO_REQUIRED_VALIDATION_ERROR,
  KNOWLEDGE_SKILL_TITLE_REQUIRED_VALIDATION_ERROR,
  EXPERIENCE_COMPANY_TITLE_REQUIRED_VALIDATION_ERROR,
  EDUCATION_UNIVERSITY_TITLE_REQUIRED_VALIDATION_ERROR,
} from './resumeConstants';
import { mobileRegExp, websiteRegExp } from '../../helpers/utils';
import { YEARS_OF_EXPERIENCE_REQUIRED_VALIDATION_ERROR } from '../registration/registrationConstants';

const contactValidationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required(FIRSTNAME_REQUIRED_VALIDATION_ERROR)
    .min(3, FIRSTNAME_MIN_LENGTH_VALIDATION_ERROR)
    .max(32, FIRSTNAME_MAX_LENGTH_VALIDATION_ERROR),
  lastName: yup
    .string()
    .required(LASTNAME_REQUIRED_VALIDATION_ERROR)
    .min(3, LASTNAME_MIN_LENGTH_VALIDATION_ERROR)
    .max(32, LASTNAME_MAX_LENGTH_VALIDATION_ERROR),
  email: yup
    .string()
    .required(EMAIL_REQUIRED_VALIDATION_ERROR)
    .email(EMAIL_VALIDATION_ERROR),
  mobile: yup
    .string()
    .required(MOBILE_REQUIRED_VALIDATION_ERROR)
    .matches(mobileRegExp, MOBILE_VALIDATION_ERROR),
  linkedin: yup
    .string()
    .required(LINKEDIN_VALIDATION_ERROR)
    .matches(websiteRegExp, LINKEDIN_REQUIRED_VALIDATION_ERROR),
  location: yup.string().required(LOCATION_REQUIRED_VALIDATION_ERROR),
});

const descriptionValidationSchema = yup.object().shape({
  info: yup.string().required(DESCRIPTION_INFO_REQUIRED_VALIDATION_ERROR),
});

const educationUniversityValidationSchema = yup.object().shape({
  title: yup
    .string()
    .required(EDUCATION_UNIVERSITY_TITLE_REQUIRED_VALIDATION_ERROR),
});

const educationValidationSchema = yup.object().shape({
  universities: yup.array().of(educationUniversityValidationSchema),
});

const experienceCompanyValidationSchema = yup.object().shape({
  title: yup
    .string()
    .required(EXPERIENCE_COMPANY_TITLE_REQUIRED_VALIDATION_ERROR),
});

const experienceValidationSchema = yup.object().shape({
  companies: yup.array().of(experienceCompanyValidationSchema),
  yearsOfExperience: yup
    .string()
    .required(YEARS_OF_EXPERIENCE_REQUIRED_VALIDATION_ERROR),
});

const knowledgeSkillValidationSchema = yup.object().shape({
  title: yup.string().required(KNOWLEDGE_SKILL_TITLE_REQUIRED_VALIDATION_ERROR),
});

const knowledgeValidationSchema = yup.object().shape({
  skills: yup.array().of(knowledgeSkillValidationSchema),
});

export const resumeValidationSchema = yup.object().shape({
  contact: contactValidationSchema,
  description: descriptionValidationSchema,
  education: educationValidationSchema,
  experience: experienceValidationSchema,
  knowledge: knowledgeValidationSchema,
});
