import React from 'react';
import { useNavigate } from 'react-router';

import ErrorContainer from '../../components/ErrorContainer/ErrorContainer';
import { withHomeLayout } from '../../layouts/home/HomeLayout';

const Error500 = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <ErrorContainer
      buttonTitle="Thanks"
      errorCode={500}
      description="Something went wrong, but we're on it."
      routeHandler={() => navigate('/', { state: {} })}
      title="That's an error!"
    />
  );
};

export default withHomeLayout(Error500);
