import React from 'react';
import { Route, Routes } from 'react-router-dom';

import BestSalary from './pages/tools/BestSalary';
import Blog from './pages/blog/Blog';
import CookiePolicy from './pages/cookiePolicy/CookiePolicy';
import ContactUs from './pages/contactUs/ContactUs';
import Dashboard from './pages/dashboard/Dashboard';
import Error404 from './pages/404/404';
import Hero from './pages/hero/Hero';
import NetSalaryCalc from './pages/tools/NetSalaryCalc';
import PrivacyPolicy from './pages/privacyPolicy/PrivacyPolicy';
import RegistrationForm from './pages/registrationForm/RegistrationForm';
import ResumeBuilder from './pages/tools/resumeBuilder/ResumeBuilder';
import SignIn from './pages/signin/SignIn';
import SignUp from './pages/signup/SignUp';
import TermsAndConditions from './pages/termsAndConditions/TermsAndConditions';

const App = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<Hero />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/cookie-policy" element={<CookiePolicy />} />
      <Route path="/contact" element={<ContactUs />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/registration-form" element={<RegistrationForm />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/tools/best-salary" element={<BestSalary />} />
      <Route path="/tools/net-salary-calc" element={<NetSalaryCalc />} />
      <Route path="/tools/resume-builder" element={<ResumeBuilder />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default App;
