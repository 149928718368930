import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { Button, Card, Slide, Snackbar, Typography } from '@mui/material';

import { icons } from '../../helpers/icons';
import FontAwesomeSvgIcon from '../FontAwesomeSvgIcon/FontAwesomeSvgIcon';

const cardStyle = {
  alignItems: 'center',
  justifyContent: 'center',
  padding: '24px',
  opacity: 0.95,
  backgroundColor: 'info.main',
  display: 'flex',
  flexDirection: { xs: 'column', md: 'row' },
  columnGap: '16px',
  rowGap: '8px',
} as const;

const svgIconStyle = {
  color: '#fff',
  width: '32px',
  height: 'auto',
  marginRight: '16px',
} as const;

const paragraphStyle = {
  color: '#fff',
  fontSize: '1rem',
  lineHeight: 1.5,
} as const;

const linkStyle = [
  {
    color: '#fff',
    textDecoration: 'none',
  },
  {
    '&:hover': {
      opacity: 0.8,
      color: 'primary.main',
    },
  },
] as const;

const CookiesBanner = () => {
  const [cookies, setCookie] = useCookies(['user_cookie_consent']);
  const [open, setOpen] = useState(!cookies['user_cookie_consent']);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if ('clickaway' === reason) {
      return;
    }

    setOpen(false);
    setCookie('user_cookie_consent', 1);
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={handleClose}
      sx={{ px: '10px' }}
      TransitionComponent={Slide}
    >
      <Card sx={cardStyle}>
        <FontAwesomeSvgIcon sx={svgIconStyle} icon={icons.faCookieBite} />
        <Typography variant="body2" sx={paragraphStyle}>
          We use cookies to help us optimize our services. Please read our{' '}
          <Typography noWrap component="a" href="/cookie-policy" sx={linkStyle}>
            cookie policy
          </Typography>
          {'.'}
        </Typography>
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={handleClose}
        >
          OK
        </Button>
      </Card>
    </Snackbar>
  );
};

export default CookiesBanner;
