import React, { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Step,
  StepContent,
  StepIconProps,
  StepLabel,
  Stepper,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import { icons } from '../../../helpers/icons';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { resumeValidationSchema } from '../../../features/resume/resumeValidations';
import { IResume } from '../../../interfaces/resume.interface';
import { createResume } from '../../../features/resume/resumeActions';
import { ContactStep } from './ContactStep';
import { DescriptionStep } from './DescriptionStep';
import { EducationStep } from './EducationStep';
import { ExperienceStep } from './ExperienceStep';
import { KnowledgeStep } from './KnowledgeStep';
import { withHomeLayout } from '../../../layouts/home/HomeLayout';

interface IStep {
  id: number;
  title: string;
  isOptional: boolean;
  isFailed: boolean;
  content: ReactNode;
}

const ResumeBuilder = (): JSX.Element => {
  const theme = useTheme<Theme>();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { error, loading, success } = useAppSelector(
    (state) => state.registration,
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IResume>({
    defaultValues: {
      education: {
        universities: [
          {
            title: '',
          },
        ],
      },
      experience: {
        companies: [
          {
            title: '',
          },
        ],
        yearsOfExperience: '',
      },
      knowledge: {
        skills: [
          {
            title: '',
          },
        ],
      },
    },
    resolver: yupResolver(resumeValidationSchema),
  });

  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [completedSteps, setCompletedSteps] = React.useState<{
    [k: number]: boolean;
  }>({});
  const [failedSteps, setFailedSteps] = React.useState<{
    [k: number]: boolean;
  }>({});

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (1 + prevActiveStep) % steps.length);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep - 1) % steps.length);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleComplete = () => {
    const newCompletedSteps = completedSteps;

    newCompletedSteps[activeStep] = true;
    setCompletedSteps(newCompletedSteps);
    handleNext();
  };

  const StepIconComponent = (props: StepIconProps) => {
    const { active, className, completed, error, icon } = props;

    if (error) {
      return (
        <FontAwesomeIcon
          icon={icons.faCircleExclamation}
          size="xl"
          style={{ color: theme.palette.error.main }}
        />
      );
    } else if (completed) {
      return <FontAwesomeIcon icon={icons.faCheckCircle} size="xl" />;
    } else {
      switch (Number(props.icon)) {
        case 1:
          return <FontAwesomeIcon icon={icons.faAddressCard} size="xl" />;
        case 2:
          return <FontAwesomeIcon icon={icons.faMemoCircleInfo} size="xl" />;
        case 3:
          return <FontAwesomeIcon icon={icons.faBrainCircuit} size="xl" />;
        case 4:
          return <FontAwesomeIcon icon={icons.faUserGraduate} size="xl" />;
        case 5:
          return <FontAwesomeIcon icon={icons.faBriefcase} size="xl" />;
        default:
          return <FontAwesomeIcon icon={icons.faStairs} />;
      }
    }
  };

  const onSubmit = (formData: IResume) => {
    dispatch(createResume({ location, navigate, application: formData }));
  };

  const steps: IStep[] = [
    {
      id: 1,
      title: 'Contact info',
      isFailed: !!errors.contact,
      isOptional: false,
      content: (
        <ContactStep
          key="contact-step"
          parentPrefix={`contact`}
          control={control}
          errors={errors}
        />
      ),
    },
    {
      id: 2,
      title: 'Description',
      isFailed: !!errors.description,
      isOptional: false,
      content: (
        <DescriptionStep
          key="description-step"
          parentPrefix={`contact`}
          control={control}
          errors={errors}
        />
      ),
    },
    {
      id: 3,
      title: 'Knowledge',
      isFailed: !!errors.knowledge,
      isOptional: false,
      content: (
        <KnowledgeStep
          key="knowledge-step"
          parentPrefix={`knowledge`}
          control={control}
          errors={errors}
        />
      ),
    },
    {
      id: 4,
      title: 'Education',
      isFailed: !!errors.education,
      isOptional: false,
      content: (
        <EducationStep
          key="education-step"
          parentPrefix={`education`}
          control={control}
          errors={errors}
        />
      ),
    },
    {
      id: 5,
      title: 'Experience',
      isFailed: !!errors.experience,
      isOptional: false,
      content: (
        <ExperienceStep
          key="experience-step"
          parentPrefix={`experience`}
          control={control}
          errors={errors}
        />
      ),
    },
  ];

  return (
    <Box>
      <CssBaseline />
      <Container component="main" maxWidth="xs">
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map(({ content, id, isFailed, title }, idx) => {
            return (
              <Step key={`${id}-${title}`} completed={completedSteps[idx]}>
                <StepLabel
                  error={isFailed}
                  StepIconComponent={StepIconComponent}
                >
                  {title}
                </StepLabel>
                <StepContent>
                  {content}
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Button
                        variant="contained"
                        onClick={
                          activeStep === steps.length - 1
                            ? handleSubmit(onSubmit)
                            : handleNext
                        }
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {steps.length - 1 === idx ? 'Finish' : 'Continue'}
                      </Button>
                      <Button
                        disabled={0 === idx}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Back
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length && (
          <>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </>
        )}
      </Container>
    </Box>
  );
};

export default withHomeLayout(ResumeBuilder);
