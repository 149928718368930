import React from 'react';
import { useNavigate } from 'react-router';

import ErrorContainer from '../../components/ErrorContainer/ErrorContainer';
import { withHomeLayout } from '../../layouts/home/HomeLayout';

const Error404 = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <ErrorContainer
      buttonTitle="You can return to our front page"
      errorCode={404}
      description="Sorry, but the page you were looking for could not be found."
      routeHandler={() => navigate('/', { state: {} })}
      title="Page not found!"
    />
  );
};

export default withHomeLayout(Error404);
