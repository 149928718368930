import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { icons } from '../../helpers/icons';

const iconWrapperStyle = {
  backgroundColor: 'secondary.main',
  borderRadius: '50%',
  boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 50,
  height: 50,
} as const;

export const Pledge = (): JSX.Element => {
  return (
    <Grid
      id="pledge"
      container
      component="section"
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ mx: { md: 0, lg: 4 }, my: 4, width: 'auto' }}
      spacing={4}
    >
      <Grid item component="header" xs={12}>
        <Typography component="h1" variant="h2">
          We commit to
        </Typography>
      </Grid>
      <Grid container item xs={12} md={6}>
        <Grid item xs={2} sm={1}>
          <Box sx={iconWrapperStyle}>
            <FontAwesomeIcon
              icon={icons.faTelescope}
              style={{ fontSize: 24 }}
            />
          </Box>
        </Grid>
        <Grid item xs={10} sm={11} sx={{ pl: { xs: 1, sm: 2 } }}>
          <Typography variant="h5" color="inherit" paragraph>
            Find companies to match your priorities and salary expectations
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={6}>
        <Grid item xs={2} sm={1}>
          <Box sx={iconWrapperStyle}>
            <FontAwesomeIcon
              icon={icons.faCalendarStar}
              style={{ fontSize: 24 }}
            />
          </Box>
        </Grid>
        <Grid item xs={10} sm={11} sx={{ pl: { xs: 1, sm: 2 } }}>
          <Typography variant="h5" color="inherit" paragraph>
            Manage applications, communicate with recruiters and get you to the
            interview
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={6}>
        <Grid item xs={2} sm={1}>
          <Box sx={iconWrapperStyle}>
            <FontAwesomeIcon
              icon={icons.faWalkieTalkie}
              style={{ fontSize: 24 }}
            />
          </Box>
        </Grid>
        <Grid item xs={10} sm={11} sx={{ pl: { xs: 1, sm: 2 } }}>
          <Typography variant="h5" color="inherit" paragraph>
            Guide you through the negotiation process to maximize salary,
            bonuses, and equity compensations
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={6}>
        <Grid item xs={2} sm={1}>
          <Box sx={iconWrapperStyle}>
            <FontAwesomeIcon icon={icons.faFileUser} style={{ fontSize: 24 }} />
          </Box>
        </Grid>
        <Grid item xs={10} sm={11} sx={{ pl: { xs: 1, sm: 2 } }}>
          <Typography variant="h5" color="inherit" paragraph>
            Make the best of your CV and tailor it to individual jobs
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={6}>
        <Grid item xs={2} sm={1}>
          <Box sx={iconWrapperStyle}>
            <FontAwesomeIcon icon={icons.faHeadset} style={{ fontSize: 24 }} />
          </Box>
        </Grid>
        <Grid item xs={10} sm={11} sx={{ pl: { xs: 1, sm: 2 } }}>
          <Typography variant="h5" color="inherit" paragraph>
            Support you with succinct preparation materials personalised for
            interviews
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={6}>
        <Grid item xs={2} sm={1}>
          <Box sx={iconWrapperStyle}>
            <FontAwesomeIcon
              icon={icons.faRankingStar}
              style={{ fontSize: 24 }}
            />
          </Box>
        </Grid>
        <Grid item xs={10} sm={11} sx={{ pl: { xs: 1, sm: 2 } }}>
          <Typography variant="h5" color="inherit" paragraph>
            Review and explain your contract and compensation package
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
