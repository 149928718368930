import {
  faFacebook,
  faFacebookF,
  faFacebookSquare,
  faGooglePlusG,
  faGulp,
  faHive,
  faHotjar,
  faHtml5,
  faInstagram,
  faInstagramSquare,
  faJs,
  faLinkedinIn,
  faMediumM,
  faNodeJs,
  faSass,
  faTrello,
  faTwitter,
  faYoutube,
  faWhatsapp,
  faWhatsappSquare,
} from '@fortawesome/free-brands-svg-icons';

import {
  faCircle as farCircle,
  faClock as farClock,
  faComment as farComment,
  faCookie as farCookie,
  faCopy as farCopy,
  faDizzy as farDizzy,
  faFile as farFile,
  faFileArchive as farFileArchive,
  faFileAudio as farFileAudio,
  faFileCode as farFileCode,
  faFileImage as farFileImage,
  faFilePdf as farFilePdf,
  faFileVideo as farFileVideo,
  faHeart as farHeart,
  faImage as farImage,
  faLaughBeam as farLaughBeam,
  faLightbulb as farLightbulb,
  faObjectUngroup as farObjectUngroup,
  faPaperPlane as farPaperPlane,
  faQuestionCircle as farQuestionCircle,
  faSmileBeam as farSmileBeam,
  faStar as farStar,
  faEdit as farEdit,
} from '@fortawesome/pro-regular-svg-icons';

import {
  fa1,
  fa2,
  fa3,
  fa4,
  fa5,
  faAddressCard,
  faAlignLeft,
  faAlignRight,
  faAngleRight,
  faAngleUp,
  faArchive,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowsAltV,
  faArrowUp,
  faAt,
  faBan,
  faBars,
  faBell,
  faBook,
  faBriefcase,
  faBrainCircuit,
  faCalculator,
  faCalculatorSimple,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarDay,
  faCalendarStar,
  faCamera,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faCartPlus,
  faChartMixed,
  faChartPie,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleCheck,
  faCircleChevronLeft,
  faCircleChevronRight,
  faCircleExclamation,
  faCircleNotch,
  faCircleQuestion,
  faClock,
  faCodeBranch,
  faCog,
  faComments,
  faCommentsDollar,
  faComputer,
  faCookieBite,
  faCopy,
  faCrown,
  faCubesStacked,
  faDollarSign,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faEnvelopeOpen,
  faEnvelopeOpenText,
  faExchangeAlt,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileArchive,
  faFilePdf,
  faFileSignature,
  faFileUser,
  faFilter,
  faFolderPlus,
  faGift,
  faGlobeAmericas,
  faGlobe,
  faHeart,
  faHeadset,
  faHome,
  faHouse,
  faIcons,
  faImage,
  faInfo,
  faLink,
  faList,
  faListAlt,
  faListCheck,
  faListUl,
  faLocationArrow,
  faLock,
  faLockKeyhole,
  faMagnifyingGlassDollar,
  faMapMarkerAlt,
  faMemoCircleInfo,
  faMinus,
  faPalette,
  faPaperclip,
  faPencilAlt,
  faPercentage,
  faTags,
  faPhone,
  faPhoneVolume,
  faPlay,
  faPlug,
  faPlus,
  faPlusSquare,
  faPoll,
  faPenNib,
  faPrint,
  faPuzzlePiece,
  faQuestionCircle,
  faRankingStar,
  faRedo,
  faReply,
  faRightFromBracket,
  faRightToBracket,
  faSearch,
  faShare,
  faShareAlt,
  faShoppingCart,
  faSignInAlt,
  faSlidersH,
  faSmileBeam,
  faSort,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortAmountDown,
  faSortAmountUp,
  faSortNumericDown,
  faSortNumericUp,
  faStar,
  faStairs,
  faStarHalfAlt,
  faStream,
  faTag,
  faTelescope,
  faTh,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTools,
  faTrash,
  faTrashAlt,
  faVideo,
  faCross,
  faFlag,
  faThumbtack,
  faTable,
  faChartLine,
  faShapes,
  faMap,
  faFire,
  faLayerGroup,
  faRocket,
  faWrench,
  faSun,
  faMoon,
  faAngleLeft,
  faInfoCircle,
  faSpinner,
  faCapsules,
  faTasks,
  faCloudDownloadAlt,
  faSyncAlt,
  faBug,
  faProjectDiagram,
  faBolt,
  faPaperPlane,
  faLongArrowAltDown,
  faLongArrowAltUp,
  faInfinity,
  faChessRook,
  faCode,
  faMusic,
  faMobile,
  faBookOpen,
  faTint,
  faRedoAlt,
  faWalkieTalkie,
  faWifiSlash,
  faUser,
  faUsers,
  faUserCircle,
  faUserFriends,
  faUserGraduate,
  faUserLock,
  faUserPlus,
  faXmark,
  faXmarkLarge,
} from '@fortawesome/pro-solid-svg-icons';

export const icons = {
  // Solid
  fa1,
  fa2,
  fa3,
  fa4,
  fa5,
  faAddressCard,
  faAngleRight,
  faAngleLeft,
  faArrowDown,
  faArrowUp,
  faArrowLeft,
  faArrowRight,
  faArrowsAltV,
  faBan,
  faBell,
  faBriefcase,
  faBrainCircuit,
  faCalculator,
  faCalculatorSimple,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarDay,
  faCalendarStar,
  faCamera,
  faCaretRight,
  faChartMixed,
  faChartPie,
  faCheck,
  faCheckCircle,
  faChessRook,
  faChevronDown,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faCircle,
  faCircleCheck,
  faCircleExclamation,
  faCircleQuestion,
  faCommentsDollar,
  faComputer,
  faCookieBite,
  faCopy,
  faCrown,
  faCubesStacked,
  faEye,
  faEyeSlash,
  faEllipsisH,
  faEnvelopeOpenText,
  faExchangeAlt,
  faFile,
  faFileAlt,
  faFileArchive,
  faFilePdf,
  faFileSignature,
  faFileUser,
  faHeart,
  faHeadset,
  faHome,
  faListAlt,
  faListCheck,
  faLock,
  faLockKeyhole,
  faMagnifyingGlassDollar,
  faMemoCircleInfo,
  faPhoneVolume,
  faPlay,
  faPenNib,
  faQuestionCircle,
  faRankingStar,
  faRedo,
  faReply,
  faRightFromBracket,
  faRightToBracket,
  faSearch,
  faSort,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortNumericDown,
  faSortNumericUp,
  faSortAmountDown,
  faSortAmountUp,
  faStairs,
  faStream,
  faShareAlt,
  faPrint,
  faDollarSign,
  faLink,
  faPlus,
  faMinus,
  faPencilAlt,
  faTimesCircle,
  faSignInAlt,
  faBook,
  faPuzzlePiece,
  faTrash,
  faAlignLeft,
  faAlignRight,
  faPlug,
  faTimes,
  faFilter,
  faExternalLinkAlt,
  faCartPlus,
  faShoppingCart,
  faStar,
  faStarHalfAlt,
  faArchive,
  faEnvelope,
  faClock,
  faLocationArrow,
  faCog,
  faPaperclip,
  faImage,
  faEllipsisV,
  faEnvelopeOpen,
  faSlidersH,
  faCaretUp,
  faCodeBranch,
  faExclamationTriangle,
  faListUl,
  faTh,
  faGift,
  faGlobeAmericas,
  faGlobe,
  faPlusSquare,
  faCaretDown,
  faCircleNotch,
  faExclamation,
  faThumbsUp,
  faComments,
  faPalette,
  faEdit,
  faTrashAlt,
  faShare,
  faCheckSquare,
  faTools,
  faCheckDouble,
  faSmileBeam,
  faPoll,
  faPercentage,
  faTags,
  faTelescope,
  faIcons,
  faList,
  faExclamationCircle,
  faFolderPlus,
  faTag,
  faAt,
  faMapMarkerAlt,
  faCross,
  faFlag,
  faThumbtack,
  faTable,
  faChartLine,
  faShapes,
  faMap,
  faFire,
  faLayerGroup,
  faRocket,
  faWrench,
  faSun,
  faMoon,
  faInfoCircle,
  faSpinner,
  faCapsules,
  faTasks,
  faCloudDownloadAlt,
  faSyncAlt,
  faBug,
  faProjectDiagram,
  faBolt,
  faPaperPlane,
  faLongArrowAltDown,
  faLongArrowAltUp,
  faInfinity,
  faMusic,
  faMobile,
  faBookOpen,
  faTint,
  faAngleUp,
  faBars,
  faCircleChevronLeft,
  faCircleChevronRight,
  faHouse,
  faUser,
  faUsers,
  faUserCircle,
  faUserFriends,
  faUserGraduate,
  faUserLock,
  faUserPlus,
  faWalkieTalkie,
  faWifiSlash,
  faXmark,
  faXmarkLarge,

  // Brand
  faCode,
  faFacebook,
  faFacebookF,
  faFacebookSquare,
  faGooglePlusG,
  faGulp,
  faHive,
  faHotjar,
  faHtml5,
  faInfo,
  faInstagram,
  faInstagramSquare,
  faJs,
  faLinkedinIn,
  faMediumM,
  faNodeJs,
  faPhone,
  faSass,
  faTrello,
  faTwitter,
  faVideo,
  faWhatsapp,
  faWhatsappSquare,
  faYoutube,

  // Regular
  farCircle,
  farClock,
  farComment,
  farCookie,
  farCopy,
  farDizzy,
  farEdit,
  farHeart,
  farLightbulb,
  farObjectUngroup,
  farPaperPlane,
  farStar,
  farImage,
  farFileArchive,
  farFilePdf,
  farFile,
  farFileVideo,
  farFileAudio,
  farFileImage,
  farFileCode,

  farQuestionCircle,

  farLaughBeam,
  farSmileBeam,
  faRedoAlt,
};

export type IconName = keyof typeof icons;
