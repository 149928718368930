import React from 'react';
import { Box, Divider, Grid, Paper, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { icons } from '../../helpers/icons';
import { priceUsd } from '../../helpers/utils';
import { ArrowLink } from '../../components/ArrowLink/ArrowLink';

const iconWrapperStyle = {
  backgroundColor: 'secondary.main',
  borderRadius: '50%',
  boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 35,
  height: 35,
} as const;

export const Pricing = (): JSX.Element => {
  return (
    <Grid
      id="pricing"
      container
      component="section"
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ mx: { md: 0, lg: 8 }, my: 2, width: 'auto' }}
      spacing={4}
    >
      <Grid item xs={12} md={6} sx={{ alignSelf: 'stretch' }}>
        <Paper elevation={12} sx={{ p: 2, height: '100%' }}>
          <Box>
            <Typography variant="h4" color="inherit" paragraph sx={{ mb: 0 }}>
              Personal career consultant
            </Typography>
          </Box>
          <Divider />
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={2} sm={1}>
              <Box sx={iconWrapperStyle}>
                <FontAwesomeIcon
                  icon={icons.faCheck}
                  style={{ fontSize: 24, color: 'fff' }}
                />
              </Box>
            </Grid>
            <Grid item xs={10} sm={11} sx={{ pl: { xs: 1, sm: 2 } }}>
              <Typography variant="h5" color="inherit" paragraph>
                Minimum one job offer from chosen companies
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={2} sm={1}>
              <Box sx={iconWrapperStyle}>
                <FontAwesomeIcon
                  icon={icons.faCheck}
                  style={{ fontSize: 24, color: 'fff' }}
                />
              </Box>
            </Grid>
            <Grid item xs={10} sm={11} sx={{ pl: { xs: 1, sm: 2 } }}>
              <Typography variant="h5" color="inherit" paragraph>
                Personal agent chat support
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={2} sm={1}>
              <Box sx={iconWrapperStyle}>
                <FontAwesomeIcon
                  icon={icons.faCheck}
                  style={{ fontSize: 24, color: 'fff' }}
                />
              </Box>
            </Grid>
            <Grid item xs={10} sm={11} sx={{ pl: { xs: 1, sm: 2 } }}>
              <Typography variant="h5" color="inherit" paragraph>
                High standard CV with adaptation to 10 chosen companies
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={2} sm={1}>
              <Box sx={iconWrapperStyle}>
                <FontAwesomeIcon
                  icon={icons.faCheck}
                  style={{ fontSize: 24, color: 'fff' }}
                />
              </Box>
            </Grid>
            <Grid item xs={10} sm={11} sx={{ pl: { xs: 1, sm: 2 } }}>
              <Typography variant="h5" color="inherit" paragraph>
                As many interviews as you need to go to the next steps
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={2} sm={1}>
              <Box sx={iconWrapperStyle}>
                <FontAwesomeIcon
                  icon={icons.faCheck}
                  style={{ fontSize: 24, color: 'fff' }}
                />
              </Box>
            </Grid>
            <Grid item xs={10} sm={11} sx={{ pl: { xs: 1, sm: 2 } }}>
              <Typography variant="h5" color="inherit" paragraph>
                Preparation materials personalised for interviews
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ mb: 2 }}>
            <Grid item xs={2} sm={1}>
              <Box sx={iconWrapperStyle}>
                <FontAwesomeIcon
                  icon={icons.faCheck}
                  style={{ fontSize: 24, color: 'fff' }}
                />
              </Box>
            </Grid>
            <Grid item xs={10} sm={11} sx={{ pl: { xs: 1, sm: 2 } }}>
              <Typography variant="h5" color="inherit" paragraph>
                As many mock interviews with FAANG engineer as you need
              </Typography>
            </Grid>
          </Grid>
          <Box>
            <ArrowLink
              variant="secondary"
              title="Start now"
              route="/registration-form"
            />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};
