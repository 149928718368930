import React from 'react';
import { useNavigate } from 'react-router';

import { withHomeLayout } from '../../layouts/home/HomeLayout';
import ErrorContainer from '../../components/ErrorContainer/ErrorContainer';

const Error401 = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <ErrorContainer
      buttonTitle="Go to login page"
      errorCode={401}
      description="You are not authorized to access this page."
      routeHandler={() => navigate('/signin', { state: {} })}
      title="Unauthorized!"
    />
  );
};

export default withHomeLayout(Error401);
