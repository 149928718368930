import React from 'react';
import { Box, Link, Typography } from '@mui/material';

import Copyright from './Copyright';

const linkStyle = [
  {
    fontWeight: 600,
    textDecoration: 'none',
  },
  {
    '&:hover': {
      opacity: 0.8,
      color: 'secondary.main',
    },
  },
] as const;

const Footer = (): JSX.Element => {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        bgcolor: (theme) => theme.palette.grey[200],
        display: 'flex',
        flexDirection: { xs: 'column-reverse', md: 'row' },
        alignContent: 'center',
        justifyContent: 'center',
        columnGap: '16px',
        rowGap: '8px',
        pt: 2,
      }}
    >
      <Copyright
        sx={{ color: (theme) => theme.palette.text.primary, fontWeight: 600 }}
      />
      <Typography variant="body2" color="text.secondary" align="center">
        <Link sx={linkStyle} color="text.primary" href="/privacy-policy">
          Privacy Policy
        </Link>
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center">
        <Link sx={linkStyle} color="text.primary" href="/terms-and-conditions">
          Terms & Conditions
        </Link>
      </Typography>
    </Box>
  );
};

export default Footer;
