import React from 'react';
import { Box } from '@mui/material';

// import { Brands } from './Brands';
import { Intro } from './Intro';
import { Pain } from './Pain';
import { Pledge } from './Pledge';
import { Pricing } from './Pricing';
import { Process } from './Process';
import { Team } from './Team';
// import { Testimonials } from './Testimonials';

import { withHomeLayout } from '../../layouts/home/HomeLayout';

const Hero = (): JSX.Element => {
  return (
    <Box sx={{ mx: 4 }}>
      <Intro />
      {/*<Brands />*/}
      <Pain />
      <Pledge />
      <Process />
      <Pricing />
      <Team />
      {/*<Testimonials />*/}
    </Box>
  );
};

export default withHomeLayout(Hero);
