import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import API from '../../helpers/api';
import { axiosErrorHandler } from '../../helpers/utils';
import { ISignIn, ISignUp } from '../../interfaces/user.interface';

export const signIn = createAsyncThunk<
  string,
  ISignIn,
  { rejectValue: unknown }
>(
  'user/signIn',
  async ({ location, navigate, user }: ISignIn, { rejectWithValue }) => {
    try {
      const { data } = await API.auth.signIn(user);

      localStorage.setItem('access-token', data.access_token);

      return data.access_token;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;

        return rejectWithValue(
          axiosErrorHandler(axiosError, location, navigate),
        );
      } else {
        return rejectWithValue(error);
      }
    }
  },
);

export const signUp = createAsyncThunk<void, ISignUp, { rejectValue: unknown }>(
  'user/signUp',
  async ({ location, navigate, user }: ISignUp, { rejectWithValue }) => {
    try {
      await API.auth.signUp(user);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;

        return rejectWithValue(
          axiosErrorHandler(axiosError, location, navigate),
        );
      } else {
        return rejectWithValue(error);
      }
    }
  },
);
