import * as yup from 'yup';

import {
  FIRSTNAME_REQUIRED_VALIDATION_ERROR,
  FIRSTNAME_MIN_LENGTH_VALIDATION_ERROR,
  FIRSTNAME_MAX_LENGTH_VALIDATION_ERROR,
  LASTNAME_REQUIRED_VALIDATION_ERROR,
  LASTNAME_MIN_LENGTH_VALIDATION_ERROR,
  LASTNAME_MAX_LENGTH_VALIDATION_ERROR,
  EMAIL_REQUIRED_VALIDATION_ERROR,
  EMAIL_VALIDATION_ERROR,
  JOB_ROLE_REQUIRED_VALIDATION_ERROR,
  YEARS_OF_EXPERIENCE_REQUIRED_VALIDATION_ERROR,
  YEARS_OF_EXPERIENCE_MIN_VALUE_VALIDATION_ERROR,
  YEARS_OF_EXPERIENCE_MAX_VALUE_VALIDATION_ERROR,
  YEARS_OF_EXPERIENCE_TYPE_VALIDATION_ERROR,
} from './registrationConstants';

export const registrationValidationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required(FIRSTNAME_REQUIRED_VALIDATION_ERROR)
    .min(3, FIRSTNAME_MIN_LENGTH_VALIDATION_ERROR)
    .max(32, FIRSTNAME_MAX_LENGTH_VALIDATION_ERROR),
  lastName: yup
    .string()
    .required(LASTNAME_REQUIRED_VALIDATION_ERROR)
    .min(3, LASTNAME_MIN_LENGTH_VALIDATION_ERROR)
    .max(32, LASTNAME_MAX_LENGTH_VALIDATION_ERROR),
  email: yup
    .string()
    .required(EMAIL_REQUIRED_VALIDATION_ERROR)
    .email(EMAIL_VALIDATION_ERROR),
  jobRole: yup.string(),
  careerGoal: yup.string(),
  yearsOfExperience: yup
    .number()
    .min(0, YEARS_OF_EXPERIENCE_MIN_VALUE_VALIDATION_ERROR)
    .max(50, YEARS_OF_EXPERIENCE_MAX_VALUE_VALIDATION_ERROR)
    .typeError(YEARS_OF_EXPERIENCE_TYPE_VALIDATION_ERROR),
});
